.infoCell {
  width: 100%;
}

.infoTitle {
  font-size: 15px;
  font-family: "Inter Semibold";
  color: var(--neutral-500);
}

.helpCircle {
  margin-left: 6px;
  color: var(--neutral-500);
}

.heading {
  line-height: 42px;
  font-size: 34px;
}

.tooltip {
  color: rgba(255, 255, 255, 1) !important;
  background-color: rgba(24, 24, 24, 1) !important;
  padding: 8px 11px;
  font-size: 12px;
  font-family: "Inter Regular";
  border-radius: 4px;
}
