.searchBarContainer {
    width: 100%;
    max-width: 600px;
    position: absolute;
    justify-content: center;
}

.searchBar {
    font-size: 16px;
    border-radius: 0.5rem;
    padding-left: 46px;
    width: 100%;
    height: 50px;
    border: 1.5px solid var(--neutral-800);
    box-shadow: var(--shadow);
    background-color: var(--neutral-875);
}

.searchIcon {
    position: absolute;
    left: 17px;
    bottom: 19px;
}