.container {
  padding-top: 40px;
  height: 100vh;
  background-color: var(--primary-10);
}

.darkContainer {
  background-color: var(--neutral-1050);
}

.loginContainer {
  width: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.noPadButton {
  padding: 0px !important;
}

.errorBorder {
  border-color: var(--red) !important;
}

.errorContainer {
  align-items: flex-start;
  column-gap: 6px;
  margin-top: -7px;
  margin-bottom: 8px;
}

.errorText {
  color: var(--red);
  text-align: left;
  max-width: 350px;
}

.errorIcon {
  margin-top: 4px;
}

.loginWrapper {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: auto;
  padding: 76px;
  box-shadow: var(--shadow);
    border-radius: 0.75rem;
    background-color: var(--neutral-1000);
}

.signInWithGoogle {
  margin-top: 16px;
  width: 350px;
}

.signInWithGoogleLink {
  width: 100%;
}

.paragraph {
  text-align: center;
}

.signUpLinkContainer {
  margin-top: 16px;
}

.googleIcon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.welcomeTitle {
  font-size: 22px;
}

.redLink {
  text-decoration: underline;
  color: var(--red) !important;
}

.inputTitle {
  font-size: 14px;
  color: var(--neutral-500);
}

.inputError {
  border: 1.5px solid var(--red) !important;
}

.title {
  margin-top: -5px;
  font-size: 26px;
}

.separator {
  width: 100%;
  height: 1px;
}

.separatorContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px;
  margin-bottom: 16px;
  column-gap: 16px;
}

.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input {
  width: 100%;
}

.button {
  width: 100%;
  min-width: 350px;
}

.magicIcon {
  margin-right: 8px;
}

@media screen and (min-width: 671px) {
  @media screen and (max-height: 824px) {
    .container {
      justify-content: flex-start !important;
      padding-top: 128px;
    }
  }
}

@media screen and (max-width: 1000px) {

}

@media screen and (max-width: 670px) {
  .container {
    padding: 0px;
    width: 100%;
  }
  .loginContainer {
    padding-top: 24px;
    width: 100%;
  }

  .loginWrapper {
    padding: 64px 44px;
    border-radius: 0px;
    width: 100%;
  }

  @media screen and (max-height: 694px) {
    .container {
      justify-content: flex-start !important;
      padding-top: 60px;
    }
  }
}

@media screen and (max-width: 430px) {
  .button {
    min-width: 0px;
  }

  .signInWithGoogle {
    width: 100%;
  }

  .loginWrapper {
    align-self: stretch;
  }
}