.defaults {
  color: var(--neutral-500);
  font-size: 16px;
  line-height: 180%;
  margin: 0;
}

.hide {
  opacity: 0;
  position: fixed;
  top: -1000px;
  font-size: 16px;
  line-height: 180%;
}

.smallSpinner {
  margin-left: 4px;
}

.editText {
  cursor: pointer;
}

.tooltip {
  color: rgba(255, 255, 255, 1) !important;
  background-color: rgba(24, 24, 24, 1) !important;
  padding: 8px 11px;
  font-size: 12px;
  font-family: "Inter Regular";
  border-radius: 4px;
}

.input {
  text-align: left;
  width: 100%;
  margin: 0px;
  margin-top: -4px;
  margin-bottom: -6.5px;
  margin-left: -4px;
  min-height: 0px;
  max-height: none;
  padding: 4px;
  border: none;
  background-color: transparent;
  font-size: 16px;
  line-height: 180%;
}


.small {
  font-size: 15px !important;
  line-height: 22px;
  max-width: 600px;
}

.big {
  font-size: 20px !important;
  max-width: 600px;
}

.center {
  text-align: center;
}

b {
  color: var(--neutral-400);
}
