.contentContainer {
    background-color: var(--neutral-1000);
    padding: 24px;
  }

  .main {
    width: 750px;
    max-width: calc(100vw - 64px);
  }

  .radioCells {
    align-items: flex-start;
    justify-content: flex-start;
    display: flex;
  }
  
  .headerContainer {
    row-gap: 4px;
  }

  .smallH5 {
    font-size: 22px;
  }

  .disabledInput {
    opacity: 0.45;
    pointer-events: none;
  }
  
  .whiteIcon {
    color: var(--neutral-1000);
  }
  
  .fullWidth {
    width: 100%;
  }
  
  .titleContainer2 {
    flex-direction: row;
    margin-bottom: -4px;
  }

  .iconButton {
    column-gap: 8px;
  }

  .spreadsheetIcon {
    min-width: 45px;
    max-width: 45px;
    min-height: 40px;
    max-height: 40px;
    margin-top: -6px;
    margin-bottom: -6px;
  }

  .smallTitle {
    font-size: 14px;
  }

  .dashed {
    cursor: pointer;
    max-width: 250px;
    border: 1.5px dashed var(--neutral-875);
    padding: 24px;
    border-radius: 0.5rem;
    transition: all 0.225s ease;
  }

  .dashed:hover {
    border: 1.5px dashed var(--neutral-400);
  }

  .absoluteInput {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: all;
    cursor: pointer;
  }

  .inputError {
    border: 1.5px solid var(--red) !important;
  }

  .cardTitle {
    pointer-events: none;
    font-family: 'Inter SemiBold';
    font-size: 21px;
    border: none;
    background-color: transparent;
    padding: 0px;
    min-height: 22px;
    max-height: 2px;
    letter-spacing: -0.4px;
    width: 100%;
  }

  .select {
    cursor: pointer;
    min-height: 45px;
    max-height: 45px;
    background-color: var(--neutral-900);
    border: 1.5px solid var(--neutral-800) !important;
    color: var(--neutral-100);
  }

  .iconContainer {
    padding-top: 1px;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    max-width: 16px;
    min-height: 16px;
    max-height: 16px;
    border-radius: 50%;
    background-color: var(--blue);
  }
  
  .paddedContainer {
    padding: 32px;
    overflow: scroll;
    max-height: 60vh;
  }
  
  .messageText {
    color: var(--neutral-400);
  }
  
  .smallIconContainer {
    margin-left: -2px;
    padding-top: 1px;
    align-items: center;
    justify-content: center;
    min-width: 14px;
    max-width: 14px;
    min-height: 14px;
    max-height: 14px;
    border-radius: 50%;
    background-color: var(--blue);
  }
  
  .separator {
    width: 100%;
    height: 1px;
  }
  
  .buttonContainer {
    align-items: center;
    justify-content: flex-start;
    background-color: var(--neutral-1000);
    padding: 16px;
  }
  
  .buttonWrapper {
    align-items: center;
    justify-content: flex-end;
  }
  
  .squareButton {
    min-width: 45px;
    max-width: 45px;
    padding: 0px;
  }
  
  @media screen and (max-width: 430px) {
    .hideInMobile {
      display: none;
    }
  
    .separator {
      display: none;
    }
  
    .buttonContainer {
      padding: 24px;
      padding-top: 0px !important;
    }
  }
  
  @media screen and (max-width: 490px) {
    .hideInMobile2 {
      display: none;
    }
  
    .fullWidthButton {
      min-width: 100%;
      max-width: 100%;
      width: 100%;
    }
  }

  @media screen and (max-width: 430px) {
    .main {
      width: 100%;
      max-width: none;
    }
  }