.defaults {
    padding: 16px 18px;
    font-size: 15px;
    border-radius: 6px;
    color: var(--neutral-100);
    border: none;
    border-width: 1.5px;
    border-style: solid;
    border-color: var(--neutral-800);
    background-color: var(--neutral-900);
    outline: none;
    transition: ease 0.225s;
    align-self: baseline;
    min-height: 85px;
  }

  .darkDefaults {
    padding: 16px 18px;
    font-size: 15px;
    border-radius: 6px;
    color: var(--neutral-100);
    border: none;
    border-width: 1.5px;
    border-style: solid;
    border-color: var(--neutral-800);
    background-color: var(--neutral-900);
    outline: none;
    transition: ease 0.225s;
    align-self: baseline;
    min-height: 85px;
  }
  
  .defaults:focus {
    border-color: var(--primary);
  }
  
  .darkDefaults:focus {
    border-color: var(--neutral-500);
  }
  
  .defaults::placeholder {
    color: var(--neutral-600);
  }
  
  .big {
    font-size: 18px;
    padding: 22px 22px;
    min-height: 125px;
  }
  
  .small {
    padding: 12px 14px;
    min-height: 75px;
  }
  
  @media screen and (max-width: 670px) {
    .big {
      font-size: 16px;
      padding: 18px;
    }
  }
  
  @media screen and (max-width: 430px) {
    .big {
      width: 100%;
    }
  }