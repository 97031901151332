.ctaButton {
  column-gap: 6px;
  padding-right: 18px;
}

.questionLabel {
  font-size: 22px;
  transition: 150ms all ease;
  white-space: initial !important;
  text-align: center;
  line-height: 150%;
}

.baseOverflow {
  overflow: scroll;
  width: 100vw;
  justify-content: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: 150ms all ease;
}

.overflow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  grid-gap: 24px;
  overflow: scroll;
  width: auto;
  justify-content: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: 150ms all ease;
}

.overflow3 {
  display: flex;
  overflow: scroll;
  width: 100%;
  justify-content: flex-start;
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: 150ms all ease;
}

.overflow > * {
  grid-row: span 2; /* Set each child element to span two rows */
}

.input {
  display: flex;
  width: 240px;
}

.overflow::-webkit-scrollbar {
  display: none;
}

.dismiss {
  opacity: 0;
  transform: translateY(12px);
}

.dismissedLabel {
  opacity: 0;
  transform: translateY(12px);
}

.handshake {
  transform: translateY(--12px);
}

.finishedContainer {
  transition: 150ms all ease;
}

.introduced {
  opacity: 1;
  transform: translateY(0px);
}

.introducedLabel {
  opacity: 1;
  transform: translateY(0px);
}

.thankYouTitle {
  color: var(--neutral-100);
}

.maxLengthParagraph {
  max-width: 320px;
}

.checkboxContainer {
  min-width: 52px;
  max-width: 52px;
  min-height: 52px;
  max-height: 52px;
  background-color: var(--light-green);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.checkbox {
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  background-color: var(--green);
  border-radius: 50%;
  padding-top: 2px;
  align-items: center;
  justify-content: center;
}

.checkboxText {
  text-align: left;
}

.checkboxRadioButton {
  max-width: none;
}

.errorCheckbox {
  background-color: var(--red);
  padding-top: 0px;
}

.errorCheckboxContainer {
  background-color: var(--light-red);
}

.fadedOut {
  opacity: 0;
  transform: translateY(12px);
  transition: all 150ms ease;
}

.fadeIn {
  opacity: 1;
  transform: translateY(0px);
}

@media screen and (max-width: 1500px) {
  .overflow3 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 670px) {
  .overflow3 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .overflow3 {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 600px) {

  .overflow {
    justify-content: flex-start;
    padding-left: 24px;
    padding-right: 24px;
    overflow: scroll;
    width: 100vw;
    transition: 150ms all ease;
    display: flex;
    flex-direction: column;
  }

  .ctaButton {
    width: calc(100vw - 48px) !important;
  }

  .input {
    width: calc(100vw - 48px) !important;
  }

  .link {
    width: calc(100vw - 48px) !important;
  }
}
