.defaults {
  font-family: "Inter SemiBold" !important;
  background: linear-gradient(to top, var(--primary), var(--primary));
  color: var(--neutral-1000) !important;
  border-width: 1.5px;
  border-style: solid;
  border-color: var(--primary) !important;
}

.defaults:hover {
  filter: brightness(1.03);
}

.bright {
  opacity: 0.65;
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.65;
  color: var(--neutral-1000) !important;
}

.disabled:hover {
  filter: brightness(1);
}
