.contentContainer2 {
    height: calc(100% - 75px);
    overflow: scroll;
  }
  
  .innerView {
    padding: 24px;
  }
  
  .landingView {
      margin-top: 24px;
      padding: 0px;
  }

  .thumbnail {
    width: 100%;
    object-fit: cover;
  }
  
  .perMonth {
    font-family: "Inter Regular";
    font-size: 14px;
    color: var(--neutral-600);
    margin-left: 2px;
  }
  
  .pricingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    column-gap: 24px;
  }
  
  .enterpriseContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    column-gap: 24px;
  }
  
  .smallIconText {
    height: 14px;
    display: flex;
    column-gap: 6px;
    align-items: center;
    justify-content: flex-start;
  }

  .videoCell {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    min-height: 0px;
    max-height: none;
    align-items: flex-start;
    justify-content: flex-start;
    width: auto;
    cursor: pointer;
  }
  
  .videoThumbnail {
    background-color: var(--neutral-875);
    width: 380px;
    height: 240px;
    border-radius: 0.7rem;
    box-shadow: var(--shadow);
    overflow: hidden;
  }

  .planButton {
    margin-left: 24px;
    width: calc(100% - 48px);
  }
  
  .sliderContainer {
    margin-left: 24px;
    width: calc(100% - 48px);
  }
  
  .wideSeparator {
    width: 100%;
    max-width: 1008px;
    min-height: 1px;
    max-height: 1px;
  }
  
  .seconds {
    font-family: "Inter ExtraBold";
  }
  
  .strike {
    text-decoration: line-through !important;
  }
  
  @media screen and (max-width: 930px) {
      .pricingContainer {
          flex-direction: column;
          row-gap: 24px;
      }
  
      .pricingCell {
          width: 100%;
      }
    }
  