.container {
  width: 100%;
  border-radius: 0.75rem;
  padding: 64px 48px;
  border: 1.5px solid var(--neutral-800);
  overflow: hidden;
}

.containerBackground {
  background-color: var(--primary-90);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.heading {
  font-family: "Inter Bold";
  white-space: normal;
  line-height: 150%;
  margin-top: -4px;
  text-align: center;
}

.paragraph {
  color: var(--secondary);
}

span.boldSpan {
  font-family: "Inter Bold";
  margin-left: 5px;
}