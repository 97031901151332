.pageHeader {
    width: 100%;
  }
  
  .pageHeaderLeftContainer {
    width: 50%;
  }
  
  .pageHeaderRightContainer {
    width: 50%;
    justify-content: flex-end;
  }
  
  .smallH5 {
    font-size: 22px;
  }
  
  .contentContainer2 {
    background-color: var(--neutral-1000);
    height: calc(100% - 75px);
    overflow: scroll;
  }
  
  .innerView {
    padding: 24px;
  }
  
  .smallIconButton {
    column-gap: 8px;
    position: relative;
  }
  
  .editListItem {
    pointer-events: all;
    padding: 0px !important;
  }
  
  .editListItem:hover {
    background-color: var(--neutral-875);
  }
  
  .dropMenu {
    background-color: var(--neutral-900);
    border: 1.5px solid var(--neutral-800);
    box-shadow: var(--shadow);
    border-radius: 0.5rem;
    color: var(--neutral-100);
    padding: 8px 0px;
    padding-bottom: 0px;
    font-family: "Inter Bold";
  }
  
  .dropMenuDivider {
    margin: 0px;
    padding: 0px;
  }
  
  .dropMenuGroup {
    margin: 0px;
    padding: 16px;
    padding-bottom: 16px;
  }
  
  .dropMenuItem {
    column-gap: 8px;
    margin: 0px;
    padding: 8px 16px;
    background-color: var(--neutral-900);
    color: var(--neutral-400);
    font-family: "Inter Regular";
  }
  
  .menuIcon {
    min-width: 16px;
    max-width: 16px;
    min-height: 16px;
    max-height: 16px;
  }
  
  .listButton {
    border-radius: 0px;
    min-height: 0px;
    max-height: none;
    justify-content: flex-start;
    width: 100%;
    line-height: 24px;
    column-gap: 8px;
    margin: 0px;
    padding: 8px 16px;
    background-color: var(--neutral-900);
    color: var(--neutral-400);
    font-family: "Inter Regular";
  }
  
  .listButton:hover {
    background-color: var(--neutral-875);
  }
  
  .absoluteInput {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: all;
    cursor: pointer;
  }