.noPadButton {
    padding: 0px !important;
    min-height: 0px;
    max-height: none;
    color: var(--neutral-100);
    column-gap: 4px;
    font-size: 15px;
    text-decoration: underline;
  }
  
  .rightContainer {
    width: 100%;
    justify-content: flex-end;
  }
  
  .cookieContainer {
    position: fixed !important;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99999;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    pointer-events: none;
    padding: 24px;
  }
  
  .cookieCell {
    pointer-events: all;
    position: relative;
    border: 1.5px solid var(--neutral-800);
    padding: 16px;
    background-color: var(--neutral-1000);
    border-radius: 0.5rem;
    max-width: 400px;
  }
  
  .cookieCloseButton {
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 0px;
    min-width: 18px;
    max-width: 18px;
    max-height: 18px;
    min-height: 18px;
  }
  
  .cookieMessage {
    color: var(--neutral-100);
    margin-top: -2px;
    margin-bottom: -2px;
  }

  .acceptAll {
    width: 100%;
  }

  @media screen and (max-width: 420px) {

    .acceptAll {
      width: calc(100vw - 80px);
      margin-bottom: -24px;
      margin-top: 8px;
    }

    .noPadButton {
      width: calc(100vw - 80px);
      margin-top: -4px;
      margin-bottom: -8px;
    }
  
    .collapse {
      flex-direction: column-reverse !important;
    }

    .rightContainer {
      justify-content: center;
    }
}