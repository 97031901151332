.containerDefaults {
  transition: ease 0.225s;
  border: none;
  cursor: pointer;
  display: flex;
  align-self: baseline;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0px !important;
  padding: 0px !important;
  min-height: 55px;
  max-height: 55px;
  border-radius: 0.65rem;
}

.defaults {
  white-space: nowrap;
  transition: ease 0.225s;
  border: none;
  letter-spacing: normal;
  cursor: pointer;
  font-family: "Inter Medium" !important;
  font-size: 15px;
  border-width: 0px;
  border-radius: 0.65rem;
  display: flex;
  align-self: baseline;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--neutral-400);
  padding: 16px 24px;
  border-width: 1.5px;
  border-style: solid;
  border-color: transparent;
  min-height: 55px;
  max-height: 55px;
  -webkit-tap-highlight-color: transparent;
}

.defaults:hover {
}

button:focus, 
button:active {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.linkElement {
  width: 100%;
}

.big {
  min-height: 65px;
  max-height: 65px;
  font-size: 18px;
  padding: 22px 32px;
}

.small {
  min-height: 45px !important;
  max-height: 45px !important;
  padding: 12px 18px !important;
  line-height: 24px;
  border-radius: 0.5rem;
}

.mini {
  min-height: 36px !important;
  max-height: 36px !important;
  padding: 10px 11px !important;
  border-radius: 0.5rem;
  font-size: 14px !important;
  margin-top: -1px;
}

.medium {
  min-height: 45px !important;
  max-height: 45px !important;
  padding: 12px 18px !important;
  line-height: 24px;
  border-radius: 0.5rem;
}

.bigContainer {
  min-height: 65px;
  max-height: 65px;
  font-size: 18px;
  padding: 22px 32px;
}

.smallContainer {
  min-height: 45px !important;
  max-height: 45px !important;
  border-radius: 0.5rem;
}

.miniContainer {
  min-height: 36px !important;
  max-height: 36px !important;
  border-radius: 0.5rem;
}

.mediumContainer {
  min-height: 45px !important;
  max-height: 45px !important;
  border-radius: 0.5rem;
}

.spinnerOverlay {
  position: absolute;
  pointer-events: none;
  background-color: var(--primary);
  height: 100%;
  border-radius: 0.5rem;
}

.linkDisabled {
  cursor: not-allowed !important;
}

.destructive {
  color: var(--red) !important;
}

.buttonTooltip {
  color: rgba(255, 255, 255, 1) !important;
  background-color: rgba(24, 24, 24, 1) !important;
    padding: 8px 11px;
    font-size: 12px;
    font-family: "Inter Regular";
    border-radius: 4px;
}

@media screen and (max-width: 670px) {
  .big {
    font-size: 16px;
    padding: 18px;
  }
}

@media screen and (max-width: 430px) {
  .big {
    width: 100%;
  }
}
