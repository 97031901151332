.blueContainer {
    background-color: rgb(16, 16, 16);
    padding: 36px;
    max-width: 550px;
}

.messageText {
    color: #fff;
    line-height: 150%;
}

.whiteMessage {
    color: #fff;
}

.heading {
    text-align: center;
    white-space: normal;
    line-height: 130%;
    margin-top: -4px;
    margin-bottom: 16px;
    color: #fff;
}

.seconds {
    font-family: 'Inter ExtraBold';
  }
  
  .strike {
    text-decoration: line-through !important;
  }

  .whiteButton {
    border-color: #fff !important;
    background: none !important;
    background-color: #fff !important;
    color: rgb(16, 16, 16) !important;
  }

  .whiteTextButton {
    column-gap: 6px;
    font-family: 'Inter Semibold';
    color: #fff !important;
  }