.list {
  border: 1.5px solid var(--neutral-800);
  box-shadow: var(--shadow);
  border-radius: 0.5rem;
  overflow: hidden;
}

.listHeader {
  width: 100%;
  padding: 16px 20px;
}

.largeListHeaderItem {
  width: 50%;
  align-items: center;
}

.smallMargDown {
  margin-top: -5.5px;
}

.editClass {
  margin-top: -6px;
  margin-bottom: -6px;
  padding-top: 0px;
  font-size: 14px;
  font-family: 'Inter Regular' !important;
}

.loadingSmall {
  margin-left: 6px;
  margin-top: -5px;
  margin-bottom: -4px;
}

.somethingElse {
  pointer-events: none;
  position: absolute;
  left: 0px;
  top: 11px;
  width: 80%;
  align-items: center;
}

.pointerEvents {
  pointer-events: all;
}

.noPointerEvents {
  pointer-events: none;
}

.markup {
  cursor: text;
}

.markup img {
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 16px;
}

.markup ul {
  font-size: 18px;
  line-height: 180%;
  color: var(--neutral-500);
  margin-left: 16px;
}

.markup ol {
  font-size: 18px;
  line-height: 180%;
  color: var(--neutral-500);
  margin-left: 22px;
  margin-top: 8px;
}

.markup p {
  font-size: 18px;
  line-height: 180%;
  color: var(--neutral-500);
}

.markup a {
  color: var(--neutral-100) !important;
  text-decoration: underline;
}

.markup h1, h2, h3, h4, h5, h6, a {
  color: var(--neutral-100);
}

.markup h1 {
  font-family: 'Inter Bold';
  font-size: 34px;
  margin-top: 16px;
  margin-bottom: 8px;
}

.markup h2 {
  font-family: 'Inter Bold';
  font-size: 24px;
  margin-bottom: 8px;
  margin-top: 16px;
}

.markup h3 {
  font-family: 'Inter Bold';
  font-size: 20px;
  margin-bottom: 8px;
  margin-top: 16px;
}

.paginationButton {
  margin-top: -9px;
  min-width: 28px;
  max-width: 28px;
  min-height: 28px;
  max-height: 28px;
  padding: 0px;
  border-radius: 4px;
  border: 1.5px solid var(--neutral-800);
  box-shadow: var(--shadow);
}

.paginationButton:hover {
  background-color: var(--neutral-875);
}

.disabledPaginationButton {
  opacity: 0.4;
  background-color: var(--neutral-1000) !important;
  cursor: not-allowed;
}

.rightContainer {
  width: 100%;
  justify-content: flex-end;
}

.listHeaderItem {
  width: 100%;
  align-items: center;
}

.inverted {
  filter: invert(1);
}

.listButton {
  border-radius: 0px;
  min-height: 0px;
  max-height: none;
  justify-content: flex-start;
  width: 100%;
  line-height: 24px;
  column-gap: 8px;
  margin: 0px;
  padding: 8px 16px;
  background-color: var(--neutral-900);
  color: var(--neutral-400);
  font-family: "Inter Regular";
}

.listButton:hover {
  background-color: var(--neutral-875);
  color: var(--neutral-100);
}

.smallListHeaderItem {
  width: 25%;
  position: absolute;
  right: 16px;
  bottom: 10px;
  align-items: center;
  justify-content: flex-end;
}

.editListItem {
  pointer-events: all;
  padding: 0px !important;
}

.editListItem:hover {
  background-color: var(--neutral-875);
}

.listContainer {
  position: static !important;
}

.listItemButton {
  position: absolute;
  z-index: 15;
  top: 0;
  left: 0;
  width: 90%;
  pointer-events: all;
}

.listItem {
  cursor: pointer;
  padding: 16px 20px;
  border-top: 1.5px solid var(--neutral-800);
  width: 100%;
  border-radius: 0px;
  justify-content: flex-start;
  transition: 0.225s all ease;
}

.accordionButton {
  justify-content: flex-end;
  padding: 8px 0px;
}

.accordionPanel {
  position: relative;
  padding: 8px 0px;
}

.accordionButton:hover {
  background-color: inherit;
}

.accordionListItem {
  cursor: pointer;
  padding: 10px 20px;
  border-top: 1.5px solid var(--neutral-800);
  width: 100%;
  border-radius: 0px;
  align-items: flex-start;
  justify-content: flex-start;
}

.accordionItem {
  width: 100%;
  border: none;
  outline: none;
}

.accordionIcon {
  color: var(--neutral-100);
}

.smallTitle {
  letter-spacing: normal;
  font-size: 15px;
}

.innerView {
  padding: 24px;
}

.emptyListContainer {
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.webhookIcon {
  margin-right: 8px;
}

.dropMenu {
  background-color: var(--neutral-1000);
  border: 1.5px solid var(--neutral-800);
  border-radius: 0.5rem;
  color: var(--neutral-100);
  padding: 8px 0px;
  padding-bottom: 0px;
  font-family: "Inter Bold";
  box-shadow: var(--shadow);
}

.dropMenuDivider {
  margin: 0px;
  padding: 0px;
}

.dropMenuGroup {
  margin: 0px;
  padding: 16px;
  padding-bottom: 16px;
}

.dropMenuItem {
  column-gap: 8px;
  margin: 0px;
  padding: 8px 16px;
  background-color: var(--neutral-900);
  color: var(--neutral-400);
  font-family: "Inter Regular";
}

.menuIcon {
  stroke: var(--neutral-600);
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
}