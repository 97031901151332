.container {
  margin-top: 124px;
  margin-bottom: 50px;
  padding: 38px 32px;
}

.menu {
  position: fixed;
  overflow: scroll;
  left: 0;
  top: 0;
  bottom: 0;
  width: 285px;
  z-index: 150;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--neutral-1000);
  border-right-width: 1.5px;
  border-right-style: solid;
  border-right-color: var(--neutral-800);
  flex-direction: column;
  box-shadow: var(--right-shadow);
}

.editInput {
  margin-top: -6.5px;
  margin-bottom: -6.5px;
}

.editName {
  font-family: "Inter Regular";
  margin-top: -4px;
  margin-bottom: -5px;
  padding-top: 4px;
  font-size: 14px;
}

.fixedMenu {
  z-index: 999;
}

.dropMenu {
  background-color: var(--neutral-900);
  border: 1.5px solid var(--neutral-800);
  box-shadow: var(--shadow);
  border-radius: 0.5rem;
  color: var(--neutral-100);
  padding: 8px 0px;
  padding-bottom: 0px;
  font-family: "Inter Bold";
}

.dropMenuDivider {
  margin: 0px;
  padding: 0px;
}

.dropMenuGroup {
  margin: 0px;
  padding: 16px;
  padding-bottom: 16px;
}

.dropMenuItem {
  column-gap: 8px;
  margin: 0px;
  padding: 8px 16px;
  background-color: var(--neutral-900);
  color: var(--neutral-400);
  font-family: "Inter Regular";
}

.menuIcon {
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
}

.profileButton {
  margin-left: 10px;
}

.notificationsContainer {
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.profileContent {
  width: calc(100% - 285px);
}

.paddedTabContent {
  padding: 24px;
}

.mobileBlocker {
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: var(--neutral-1000);
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}

.smallTitle {
  font-size: 14px;
}

.smallParagraph {
  font-size: 13px;
  margin-top: 2px;
  font-family: "Inter Regular";
}

.bellButton {
  padding: 8px !important;
}

.bellIcon {
  stroke: var(--neutral-500);
  stroke-width: 1.5px;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  pointer-events: all;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: all;
  opacity: 0;
  cursor: pointer;
}

.hidden {
  display: none;
}

.hiddenOverlay {
  position: absolute;
  width: 100%;
  pointer-events: all;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.fullWidth {
  width: 100%;
}

.settingsContainer {
  height: calc(100% - 70px);
}

.settingsNav {
  row-gap: 4px;
  width: 285px;
  height: 100%;
  padding: 20px 16px;
  background-color: var(--neutral-950);
  border-right: 1.5px solid var(--neutral-800);
}

.wideSeparator {
  width: 100%;
  min-height: 1px;
  max-height: 1px;
}

.settingsTitle {
  font-size: 22px;
}

.settingsContent {
  row-gap: 4px;
  width: calc(100% - 500px);
  height: calc(100% - 70px);
  padding: 48px;
}

.hiddenOverlay:hover {
  opacity: 1;
}

.headerImage {
  width: 100%;
  height: 200px;
  background-color: var(--neutral-875);
  margin-bottom: -105px;
}

.locationContainer {
  padding: 48px;
  padding-top: 0px;
  margin-top: -16px;
}

.profileBlock {
  padding: 48px;
}

.largeBellIcon {
  stroke: var(--neutral-400);
  stroke-width: 1.5px;
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
}

.dropMenuItem:hover {
  background-color: var(--neutral-875);
  color: var(--neutral-100);
}

.dropMenuDivider {
  width: 100%;
  height: 1px;
}

.userTooltip {
  color: rgba(255, 255, 255, 1) !important;
  background-color: rgba(24, 24, 24, 1) !important;
  padding: 8px 11px;
  font-size: 12px;
  font-family: "Inter Regular";
  border-radius: 4px;
}

.wrapper {
  position: fixed;
  left: 285px;
  right: 0;
  top: 0;
  bottom: 0;
}

.topContainer {
  margin-bottom: 8px;
}

.titleWrapper {
  width: calc(100% - 285px);
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 1px;
  max-height: 75px;
  min-height: 75px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--neutral-1000);
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
  border-bottom-color: var(--neutral-800);
}

.rightContainer {
  width: 100%;
  justify-content: flex-end;
}

.rightContainer2 {
  width: 50px;
  justify-content: flex-end;
  align-items: center;
}

.pageTitle {
  font-size: 18px;
}

.appParagraph {
  font-family: "Sora Regular";
  color: var(--neutral-100);
  margin-top: 1.5px;
  font-size: 14px;
  letter-spacing: -0.6px;
}

.logo {
  margin-top: 0px;
  pointer-events: none;
}

.logoText {
  font-size: 20px !important;
}

.icon {
  min-width: 26px;
  max-width: 26px;
  min-height: 26px;
  max-height: 26px;
  margin-top: 0px;
  margin-left: -0px;
  pointer-events: none;
}

.wordpressButton {
  min-width: 108px;
  max-width: 108px;
}

.shopifyButton {
  min-width: 108px;
  max-width: 108px;
}

.ghostButton,
.webhookButton {
  min-width: 108px;
  max-width: 108px;
}

.webflowButton {
  color: #fff !important;
  background-color: #4353ff !important;
  border-color: #4353ff !important;
  background: none;
  min-width: 108px;
  max-width: 108px;
}

.webflowButton:hover {
  color: #fff !important;
  background-color: #4353ff !important;
  border-color: #4353ff !important;
}

.squareButton {
  padding: 0px !important;
  min-width: 28px !important;
  max-width: 28px !important;
  min-height: 28px !important;
  max-height: 28px !important;
  border-radius: 0.5rem;
}

.logoContainer {
  max-height: 75px;
  min-height: 75px;
  flex-direction: column;
}

.logoWrapper {
  max-height: 74px;
  min-height: 74px;
  column-gap: 2.5px;
  color: var(--neutral-100);
  padding: 24px;
  justify-content: flex-end;
}

.separator {
  width: 100%;
  min-height: 1px;
  max-height: 1px;
}

.semiSeparator {
  width: calc(100% - 48px);
  margin-left: 24px;
}

.message {
  text-align: center;
  max-width: 450px;
}

.largeTabButton {
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 42px;
  max-height: 42px;
  padding: 12px 16px !important;
}

.largeTabButton:hover {
  background-color: var(--neutral-900);
}

.tabButton {
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 42px;
  max-height: 42px;
  overflow: hidden;
  padding: 10px !important;
  transition: none;
  border: none;
  border-radius: 0.5rem;
}

.disabledTabButton {
  opacity: 0.65;
}

.disabledTabButton:hover {
  color: var(--neutral-400) !important;
  background-color: transparent !important;
}

.suggestionsText {
  font-size: 11px;
}

.suggestionButton {
  min-height: 40px;
  max-height: 40px;
  padding: 12px !important;
  background-color: var(--neutral-900);
}

.regenerateButton {
  pointer-events: all;
  background-color: var(--neutral-1000);
  border: 1.5px solid var(--neutral-800);
}

.regenerateButton:hover {
  color: var(--neutral-100);
  background-color: var(--neutral-875);
}

.suggestionButton:hover {
  color: var(--neutral-100);
  background-color: var(--neutral-875);
}

.autoWidth {
  width: auto;
}

.suggestionsContainer {
  width: calc(100% - 34px);
  pointer-events: all;
  position: relative;
}

.dotLoading {
  margin-right: 24px;
  margin-top: -8px;
  font-weight: bold;
  display: inline-block;
  font-size: 20px;
  color: var(--neutral-400);
  clip-path: inset(0 1.5ch 0 0);
  animation: dotLoadingAnimation 1.5s steps(4) infinite;
}

.verified {
  margin-left: 6px;
  width: 18px;
  height: 18px;
}

@keyframes dotLoadingAnimation {
  to {
    clip-path: inset(0 -0.5ch 0 0);
  }
}

.chatMenu {
  position: fixed;
  top: 75px;
  left: 75px;
  width: 235px;
  height: calc(100% - 75px);
  background-color: var(--neutral-950);
  border-right: 1.5px solid var(--neutral-800);
  overflow: scroll;
}

.suggestionsButtons {
  overflow: scroll;
  display: flex;
  width: calc(100% - 15px);
  padding-right: 25px;
  column-gap: 8px;
}

.rightFader {
  height: 80px;
  width: 64px;
  position: absolute;
  z-index: 1;
  right: 0px;
  top: 0px;
  background: linear-gradient(to left, var(--neutral-1000) 40%, transparent);
  pointer-events: none;
  transition: 225ms all ease;
}

.leftFader {
  height: 80px;
  width: 64px;
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 0px;
  background: linear-gradient(to right, var(--neutral-1000) 40%, transparent);
  pointer-events: none;
  transition: 225ms all ease;
}

.maxWidth {
  max-width: 171px;
  min-width: 171px;
  overflow: hidden;
}

.maxWidthSelected {
  max-width: 131px;
  min-width: 131px;
  overflow: hidden;
}

.hideFader {
  opacity: 0;
}

.inverted {
  filter: invert(1);
}

.invertedTabButton {
  font-family: "Inter SemiBold";
  color: var(--neutral-1000);
  background-color: var(--neutral-100);
}

.invertedTabButton:hover {
  color: var(--neutral-1000) !important;
  background-color: var(--neutral-100) !important;
}

.moveHandle {
  padding: 6px 2px;
  border-radius: 6px;
  background-color: var(--neutral-100);
}

.fixedTabButton {
  margin-left: 0px;
  min-height: 42px;
  max-height: 42px;
  min-width: 42px;
  max-width: 42px;
  column-gap: 30px;
  overflow: hidden;
  padding: 10px !important;
}

.tabLink {
  width: 100%;
}

.selectedTabButton {
  background-color: var(--neutral-875) !important;
  color: var(--neutral-100) !important;
}

.tabButton:hover {
  background-color: var(--neutral-900);
  color: var(--neutral-100);
}

.backdropContainer {
  width: 100%;
  height: 350px;
  position: relative;
  margin-bottom: -225px;
}

.backdrop {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.smallImage {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 12px;
}

.contentContainer {
  height: calc(100% - 75px);
  overflow: scroll;
}

.savedChats {
  row-gap: 4px;
}

.tagMargin {
  margin-right: -4px;
}

.rotateIcon {
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
}

.newConvoButton {
  margin-top: 4px;
  font-size: 14px;
  min-height: 42px !important;
  max-height: 42px !important;
  padding: 8px 14px !important;
  column-gap: 10px !important;
  text-align: left;
  justify-content: flex-start;
}

.conversationButton {
  overflow: hidden;
  position: relative;
  font-size: 14px;
  min-height: 42px !important;
  max-height: 42px !important;
  padding: 8px 14px !important;
  width: 100%;
  text-align: left;
  justify-content: flex-start;
}

.conversationButton:hover {
  color: var(--neutral-100);
  background-color: var(--neutral-900);
}

.currentConversation {
  background-color: var(--neutral-800) !important;
  color: var(--neutral-100) !important;
}

.chatInputContainer {
  position: fixed;
  bottom: 0;
  padding-left: calc(235px + 75px);
  height: 100%;
  justify-content: flex-end;
  pointer-events: none;
}

.chatInputBlock {
  pointer-events: all;
  z-index: 2;
  width: 100%;
  height: 70px;
  border: 1.5px solid var(--neutral-800);
  background-color: var(--neutral-1000);
  border-radius: 10px;
}

.chatButton {
  padding: 0px;
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
  border-radius: 6px;
}

.chatMessageContainer {
  position: relative;
}

.showMoreButton {
  font-size: 12px;
  line-height: 15px;
  padding: 0px;
  min-height: 0px;
  max-height: none;
  margin-top: 16px;
}

.messageCellContainer {
  width: calc(100% - 52px);
}

.codeBlock {
  padding: 0px;
  margin: 16px 0px;
  background-color: #000;
  border-radius: 6px;
  font-family: "Courier New", monospace;
  line-height: 1.4;
  width: 100%;
}

.codeHeader {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: var(--neutral-875);
  padding: 8px 12px;
}

.codeText {
  max-width: 100%;
  overflow: scroll;
  padding: 16px;
}

.codeBlockTitle {
  color: var(--neutral-100);
  font-size: 12px;
  line-height: 15px;
  width: 100%;
  font-family: "Inter Regular";
}

.fixedSquareButton {
  background: linear-gradient(to bottom, var(--neutral-1000), transparent);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 24px;
  padding-left: 22px;
  z-index: 3;
}

.chatBottomMessage {
  max-width: none;
  text-align: center;
  pointer-events: all;
}

.chatCell {
}

.copyChatButton:hover {
  background-color: var(--neutral-875);
}

.copyChatMessageContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 4px;
  justify-content: flex-end;
  opacity: 0;
}

.copyChatMessageContainer:hover {
  opacity: 1;
}

.chatMessage {
  max-width: 100%;
  pointer-events: all;
  position: relative;
  z-index: 2;
  color: var(--neutral-400);
  margin-top: 2px;
  font-size: 15px;
  line-height: 200%;
  margin-right: 34px;
}

.sendButton {
  position: relative;
  margin-right: 18px;
  margin-top: 1px;
}

.blinker {
  animation: blinkAnimation 1s infinite;
  margin-bottom: 8px;
}

.typingIndicator {
  width: 6px;
  height: 18px;
  background-color: var(--neutral-500);
}

.logoTypingIndicator {
  margin-top: 4px;
  margin-left: 4px;
  width: 3px;
  height: 16px;
  background-color: var(--neutral-500);
}

@keyframes blinkAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.attachButton {
  margin-left: 18px;
  border-radius: 50%;
}

.attachButton:hover {
  background-color: var(--neutral-850) !important;
}

.chatInput {
  width: 100%;
  border: none !important;
  background-color: transparent;
  color: var(--neutral-100);
  line-height: 24px;
  padding-top: 29px;
  padding-left: 17px;
}

.chatInput::placeholder {
  color: var(--neutral-700);
}

.chatInput:focus {
  border: 1.5px solid var(--neutral-800);
}

.scrollDownButtonContainer {
  position: absolute;
  min-width: 34px;
  max-width: 34px;
  min-height: 34px;
  max-height: 34px;
  right: 32px;
}

.scrollDownButton {
  min-width: 34px;
  max-width: 34px;
  min-height: 34px;
  max-height: 34px;
  border-radius: 0.5rem;
}

.chatInputWrapper {
  padding: 16px 0px;
  width: calc(100% - 64px);
  max-width: 780px;
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
  border-bottom-width: 0px;
}

.chatProfileMargin {
  margin-top: 2px;
  margin-right: 6px;
}

.smallMoreButton {
  min-width: 34px;
  max-width: 34px;
  min-height: 34px;
  max-height: 34px;
  padding: 0;
  pointer-events: all;
}

.smallMoreButton:hover {
  background-color: var(--neutral-875);
}

.chatProfilePicture {
  position: relative;
  z-index: 0;
  border: 1.5px solid var(--neutral-800);
  border-radius: 6px;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
}

.maxHeight {
  height: calc(100% - 144px);
  overflow: scroll;
  display: none;
  justify-content: flex-end;
  flex-direction: column;
}

.scrollContainer {
  padding-top: 144px;
  padding-bottom: 64px;
  display: flex;
  align-items: center;
  flex-direction: column-reverse; /* Reverse the order to push content to the bottom */
  height: calc(100% - 140px); /* Set the desired height of the container */
  overflow: auto; /* Enable scrolling when content overflows */
  margin: 0px 32px;
  margin-left: 267px;
}

/* Style for the inner content */
.content {
  width: 100%;
  max-width: 780px;
  margin-top: auto; /* Push the content to the bottom */
  display: flex;
  flex-direction: column; /* Optional: Adjust this based on your content layout */
}

.content2 {
  width: 100%;
  margin-top: 0; /* Push the content to the bottom */
  display: flex;
  flex-direction: column; /* Optional: Adjust this based on your content layout */
}

.chatMessagesContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  width: calc(100% - 64px);
  max-width: 780px;
}

.topGradient {
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  height: 128px;
  margin-bottom: -128px;
  background: linear-gradient(to bottom, var(--neutral-1000), transparent);
}

.list {
  border: 1.5px solid var(--neutral-800);
  border-radius: 0.5rem;
  overflow: hidden;
}

.listHeader {
  background-color: var(--neutral-900);
  padding: 16px 20px;
  border-bottom: 1.5px solid var(--neutral-800);
}

.largeListHeaderItem {
  width: 50%;
  align-items: center;
}

.listHeaderItem {
  width: 25%;
  align-items: center;
}

.listButton {
  border-radius: 0px;
  min-height: 0px;
  max-height: none;
  justify-content: flex-start;
  width: 100%;
  line-height: 24px;
  column-gap: 8px;
  margin: 0px;
  padding: 8px 16px;
  background-color: var(--neutral-900);
  color: var(--neutral-400);
  font-family: "Inter Regular";
}

.listButton:hover {
  background-color: var(--neutral-875);
}

.returnButton {
  padding: 0px;
  min-height: 0px;
  max-height: none;
  column-gap: 8px;
}

.returnButton:hover {
  color: var(--neutral-100);
}

.smallListHeaderItem {
  width: 25%;
  align-items: center;
  justify-content: flex-end;
}

.editListItem {
  pointer-events: all;
  padding: 0px !important;
}

.editListItem:hover {
  background-color: var(--neutral-875);
}

.listContainer {
  position: static !important;
}

.listItemButton {
  position: absolute;
  z-index: 15;
  top: 0;
  left: 0;
  width: 90%;
  pointer-events: all;
}

.listItem {
  cursor: pointer;
  padding: 10px 20px;
  border-bottom: 1.5px solid var(--neutral-800);
  width: 100%;
  border-radius: 0px;
  justify-content: flex-start;
}

.listItem:hover {
  background-color: var(--neutral-900) !important;
}

.accordionItem {
  width: 100%;
  border: none;
  outline: none;
}

.accordionButton {
  padding: 16px 20px;
}

.accordionButton:hover {
  background-color: inherit;
}

.accordionIcon {
  color: var(--neutral-100);
}

.accordionPanel {
  padding: 16px 20px;
  padding-top: 0px;
}

.credits {
  width: auto;
  padding: 4px 8px;
  font-size: 16px;
  border-radius: 0.75rem;
  background-color: var(--primary-10);
  color: var(--neutral-100);
  align-self: baseline;
}

.innerView {
  padding: 24px;
}

.smallIconButton {
  column-gap: 8px;
}

.smallH5 {
  font-size: 22px;
}

.uppercaseTitle {
  font-size: 13px;
  font-family: "Inter Semibold";
  letter-spacing: 0.6px;
}

.verticalSeparator {
  height: 80%;
  width: 1px;
}

.buttonTooltip {
  color: rgba(255, 255, 255, 1) !important;
  background-color: rgba(24, 24, 24, 1) !important;
  padding: 8px 11px;
  font-size: 12px;
  font-family: "Inter Regular";
  border-radius: 4px;
}

.draftTypeCell {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 20px;
  color: var(--neutral-100);
  min-width: 150px;
  max-width: 150px;
  height: 150px;
  overflow: hidden;
  border: 1.5px solid var(--neutral-800);
  border-radius: 0.5rem;
  box-shadow: var(--shadow);
  transition: all 0.225s ease;
}

.draftTypeCell:hover {
  border-color: var(--neutral-400);
}

.draftTypeCellTitle {
  font-size: 14px;
}

.draftTypeCellOverlay {
  padding: 0px !important;
  min-height: 0px;
  max-height: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.225s ease;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--neutral-1000-alpha);
  opacity: 0;
}

.draftTypeCellOverlay:hover {
  opacity: 1;
  transform: scale(1.3);
}

.draftTypeContainer {
  width: 100%;
  overflow-x: scroll;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;
}

.banner {
  width: 100%;
  font-family: "Inter Bold";
}

.pageHeader {
  width: 100%;
}

.pageHeaderLeftContainer {
  width: 50%;
}

.pageHeaderRightContainer {
  width: 50%;
  justify-content: flex-end;
}

.contentContainer2 {
  background-color: var(--neutral-1000);
  height: calc(100% - 75px);
  overflow: scroll;
}

.relativeContainer {
  position: relative;
}

.absoluteInput {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: all;
  cursor: pointer;
}

.backdropGradient {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(to top, var(--neutral-1000), transparent);
}

.landingTitle {
  text-shadow: 1px 1px 2px var(--neutral-1000);
  max-width: 550px;
  font-size: 30px;
  line-height: 150%;
  text-align: center;
}

.dashed {
  max-width: 540px;
  border: 1.5px dashed var(--neutral-800);
  padding: 48px;
  border-radius: 0.5rem;
}

.buttonContainer {
  padding: 16px;
  row-gap: 4px;
  flex-direction: column;
}

.plusTabIcon {
  margin-right: 10px;
  min-width: 21px;
  min-height: 21px;
  max-width: 21px;
  max-height: 21px;
}

.tabIcon {
  margin-right: 10px;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
}

.titleContainer {
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 0px;
  margin-bottom: -4px;
}

.title {
  font-size: 13px;
}

.fixedTitle {
  display: none;
}

.createButton {
  width: calc(285px - 32px);
  margin-left: 16px;
  border-radius: 50px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.smallIcon {
  min-width: 14px;
  max-width: 14px;
  min-height: 14px;
  max-height: 14px;
}

.tag {
  font-size: 11px;
  padding: 3px 6px;
  background-color: var(--neutral-875);
  border-radius: 4px;
  margin-left: 8px;
  line-height: 14px;
  width: auto;
}

.darkerTitle {
  color: var(--neutral-600);
}

.tagButton {
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  font-size: 11px;
  padding: 0px;
  background-color: "transparent";
  border-radius: 4px;
  line-height: 14px;
  width: auto;
}

.tagButton:hover {
  background-color: "transparent";
}

.invertedTag {
  background-color: var(--neutral-400);
  color: var(--neutral-100);
}

.selectedTag {
  background-color: var(--neutral-875);
}

.selectedCommandMessage {
  color: var(--neutral-100) !important;
}

.invertedCommandMessage {
  color: var(--neutral-800) !important;
}

.commandText {
  font-size: 10px !important;
  line-height: 15px;
  color: var(--neutral-500);
  margin-left: 2px;
}

.commandText2 {
  font-size: 11px !important;
  line-height: 14px;
  color: var(--neutral-500);
}

.perMonth {
  font-family: "Inter Regular";
  font-size: 14px;
  color: var(--neutral-600);
  margin-left: 2px;
}

.pricingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 850px;
  width: 100%;
  height: 100%;
  column-gap: 24px;
}

.enterpriseContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 850px;
  width: 100%;
  height: 100%;
  column-gap: 24px;
}

.smallIconText {
  height: 14px;
  display: flex;
  column-gap: 6px;
  align-items: center;
  justify-content: flex-start;
}

.planButton {
  margin-left: 24px;
  width: calc(100% - 48px);
}

.largeSpinner {
  min-width: 35px;
  max-width: 35px;
  min-height: 35px;
  max-height: 35px;
}

.sliderContainer {
  margin-left: 24px;
  width: calc(100% - 48px);
}

.price {
  margin-bottom: -6px;
}

.pricingCell {
  border: 1.5px solid var(--neutral-800);
  border-radius: 0.5rem;
  width: 100%;
}

.pricingCellTop {
  padding: 24px;
}

.iconButton {
  column-gap: 8px;
}

.tooltip {
  color: rgba(255, 255, 255, 1) !important;
  background-color: rgba(24, 24, 24, 1) !important;
  padding: 8px 11px;
  font-size: 12px;
  font-family: "Inter Regular";
  border-radius: 4px;
}

.userTitle {
  font-size: 22px;
}

.handleContainer {
  margin-top: 8px;
}

.handle {
  color: var(--neutral-100);
  margin-right: 4px;
}

.show670 {
  display: none;
}

@media screen and (max-width: 670px) {
  .container {
    margin-top: 74px;
    margin-bottom: 0px;
  }

  .hide670 {
    display: none;
  }

  .show670 {
    display: flex;
  }
}

@media screen and (max-width: 500px) {
  .mobileBlocker {
    display: flex;
  }
}

@media screen and (max-width: 430px) {
  .waitlistContainer {
    width: 100%;
    flex-direction: column !important;
    row-gap: 24px;
  }
}
