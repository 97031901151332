.container {
  position: relative;
  z-index: 14;
  padding: 18px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--neutral-1000);
  border-radius: 1rem;
}

.iconLink {
  color: #fff !important;
  display: inline-flex;
  column-gap: 4px;
  align-items: center;
  justify-content: center;
}

.alertMessage {
  position: relative;
  z-index: 13;
  padding: 10px 14px;
  padding-bottom: 26px;
  background: linear-gradient(to right, var(--primary), var(--secondary));
  align-items: center;
  justify-content: center;
}

.wrapper {
  background-color: var(--neutral-1000);
  border-bottom: 1.5px solid var(--neutral-800);
  top: 0px;
  left: 0px;
  right: 0px;
  max-width: 100%;
  position: fixed;
  z-index: 200;
}

.alertText {
  color: #fff;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.45);
  font-size: 14px !important;
}

.signOutButton {
  margin-left: -8px;
}

.iconButton {
  column-gap: 8px;
}

.filledContainer {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: var(--neutral-1000);
  z-index: 200;
  height: 81px;
  padding: 32px;
  padding-right: 23px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
  border-bottom-color: var(--neutral-800);
}

.symmetricalButton {
  padding: 0px;
  min-width: 45px;
  max-width: 45px;
}

.tag {
  color: var(--neutral-1000);
  column-gap: 6px;
  padding: 3px 8px;
  min-height: 0px !important;
  font-size: 11px;
  font-family: "Inter SemiBold";
  background-color: var(--neutral-100);
  border-radius: 50px;
}

.logoContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.logo {
  margin-top: 3px;
  margin-left: 10px;
  pointer-events: none;
}

.logoText {
  margin-top: 2px;
  font-size: 24px !important;
}

.blinker {
  animation: blinkAnimation 1s infinite;
  margin-bottom: 8px;
}

.typingIndicator {
  width: 6px;
  height: 18px;
  background-color: var(--neutral-500);
}

.logoTypingIndicator {
  margin-top: 2px;
  margin-left: 4px;
  width: 3px;
  height: 19px;
  background-color: var(--neutral-500);
}

@keyframes blinkAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.icon {
  margin-left: -5px;
  margin-right: 9px;
  min-width: 22px;
  max-width: 22px;
  min-height: 15px;
  max-height: 15px;
  object-fit: cover;
  pointer-events: none;
}

.centerContainer {
  position: fixed;
  padding: 18px;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 14;
}

.clearSecondaryButton {
  border-color: transparent !important;
  box-shadow: none !important;
}

.fixedLogo {
  position: fixed;
  top: 17px;
  left: 22px;
}

.navButton {
  padding: 8px;
}

.mainNavButton {
  margin-left: 8px;
  margin-right: -4px;
}

.buttonContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  column-gap: 16px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  column-gap: 16px;
}

.desktopButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  column-gap: 16px;
}

.mobileButtonContainer {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: static !important;
  margin-right: -7px;
}

.mobileMenu {
  z-index: 14;
}

.drawer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  transition: transform 0.3s ease-out;
  transform: translateX(100%);
}

.drawerOpen {
  transform: translateX(0);
}

.categoryButton {
  color: var(--neutral-400);
  column-gap: 8px;
}

.currentCategoryProfile {
  border: 1.5px solid var(--neutral-400);
  border-radius: 50%;
}

.categoryButton:hover {
  color: var(--neutral-100);
  background-color: var(--neutral-950);
}

.currentCategory {
  color: var(--neutral-100);
  background-color: var(--neutral-900);
}

.categoryButtonContainer {
  overflow: scroll;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}

.rightFader {
  display: none;
  height: 80px;
  width: 64px;
  position: fixed;
  right: 0px;
  top: 0px;
  background: linear-gradient(to left, var(--neutral-1000) 40%, transparent);
  pointer-events: none;
  transition: 225ms all ease;
}

.leftFader {
  height: 80px;
  width: 64px;
  position: fixed;
  left: 48px;
  top: 0px;
  background: linear-gradient(to right, var(--neutral-1000) 40%, transparent);
  pointer-events: none;
  transition: 225ms all ease;
}

.hideFader {
  opacity: 0;
}

.show670 {
  display: none;
}

@media screen and (max-width: 670px) {

  .centerContainer {
    display: none;
  }

  .alertMessage {
    display: none;
  }

  .container {
    margin-top: 0px;
  }

  .signOutButton {
    margin-left: -0px;
  }

  .show670 {
    display: flex;
  }

  .rightFader {
    display: flex;
    height: 70px;
  }

  .leftFader {
    height: 70px;
  }

  .buttonContainer {
    margin-right: -3px;
    column-gap: 8px;
    position: static;
  }

  .hide670 {
    display: none;
  }

  .icon {
    margin-left: -6px;
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
  }

  .desktopButtonContainer {
    display: none;
  }

  .mobileButtonContainer {
    display: flex;
  }

  .alertMessage {
    border-radius: 0px;
  }

  .wrapper {
    top: 0px;
    left: 0px;
    right: 0px;
    max-width: none;
    border-radius: 0px;
  }

  .container {
    padding-right: 24px;
  }

  .filledContainer {
    height: 71px;
    padding: 24px;
  }

  .logo {
    margin-left: 4px;
    pointer-events: none;
  }
}
