.contentContainer2 {
  height: calc(100% - 75px);
  overflow: scroll;
}

.innerView {
  padding: 24px;
}

.landingView {
    margin-top: 24px;
    padding: 0px;
}

.perMonth {
  font-family: "Inter Regular";
  font-size: 15px;
  color: var(--neutral-500);
  margin-left: 2px;
}

.secondaryColor {
  color: var(--secondary);
}

.subtitle {
  font-size: 38px;
  font-family: "Inter Bold";
  max-width: 100%;
  text-align: center;
  white-space: normal;
  line-height: 150%;
}

.heartHandshake {
  min-width: 48px;
  max-width: 48px;
  min-height: 48px;
  max-height: 48px;
}

.miniTitle {
  color: var(--primary);
  font-size: 16px;
}

.tagTitle {
  text-transform: uppercase;
  letter-spacing: 0.8px;
  align-self: baseline;
  padding: 6px 8px;
  width: auto;
  color: var(--neutral-1000);
  background-color: var(--primary);
  border-radius: 25px;
  font-size: 11px;
  margin-bottom: 4px;
}

.scaleUp {
  border-color: var(--secondary) !important;
}

.pseudoButton {
  height: 45px;
  width: calc(100% - 48px);
  margin-left: 24px;
  border: 1.5px solid var(--neutral-800);
  border-radius: 0.5rem;
  box-shadow: var(--shadow);
}

.pricingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  column-gap: 24px;
}

.enterpriseContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  column-gap: 24px;
}

.smallIconText {
  display: flex;
  column-gap: 6px;
  align-items: flex-start;
  justify-content: flex-start;
}

.planButton {
  margin-left: 24px;
  width: calc(100% - 48px);
}

.sliderContainer {
  margin-left: 24px;
  width: calc(100% - 48px);
}

.price {
  margin-bottom: -6px;
}

.pricingCell {
  border: 1.5px solid var(--neutral-800);
  box-shadow: var(--shadow);
  border-radius: 0.5rem;
  width: 320px;
}

.pricingCellTop {
  padding: 24px;
}

.wideSeparator {
  width: 100%;
  max-width: 1008px;
  min-height: 1px;
  max-height: 1px;
}

.seconds {
  font-family: "Inter ExtraBold";
}

.strike {
  text-decoration: line-through !important;
}

@media screen and (max-width: 670px) {
  .subtitle {
    font-size: 32px !important;
  }

  .secondaryColor {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 930px) {
    .pricingContainer {
        flex-direction: column;
        row-gap: 24px;
    }

    .pricingCell {
        width: 100%;
    }
  }

  @media screen and (max-width: 430px) {
    .subtitle {
      font-size: 24px !important;
    }

    .secondaryColor {
      font-size: 16px !important;
    }
  }