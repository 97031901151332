.container {
    padding: 0px;
    margin: 0px;
    align-items: center;
    justify-content: flex-start;
}

.header {
    padding: 108px;
    padding-top: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 12px;
    background-color: var(--primary-90);
}

.centerText {
  text-align: center;
}

.articleContainer {
    padding: 64px;
    width: 100%;
    max-width: 1000px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.loadingArticleContainer {
  margin-top: 216px;
}

.editorContainer {
    overflow: hidden;
    padding: 64px;
    width: 100%;
    max-width: 1000px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.articleContainer2 {
  row-gap: 0px;
}

.articleCell {
    border: none;
    padding: 28px;
}

.summary {
    margin: 0px;
    padding: 0px;
    margin-bottom: -4px;
}

.clockIcon {
    margin-right: 6px;
}

.infoContainer {
    width: auto;
    margin-bottom: -8px;
}

.smallIconButton {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
  }

  .blogInput {
    width: 100%;
  }

  .blogArticleContainer {
    overflow: hidden;
    padding: 64px;
    padding-top: 150px;
    width: 100%;
    max-width: 1000px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  .noBorder {
    border: none !important;
    box-shadow: none !important;
}

  .draftsIcon {
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    margin-bottom: 4px;
    border-radius: 50%;
    box-shadow: var(--large-shadow);
  }

  .articleHeading {
    font-size: 15px;
    margin-bottom: 4px;
  }

  .markup {
    cursor: text;
  }

  .markup img {
    width: 100%;
    border-radius: 0.5rem;
    margin-bottom: 16px;
  }

  .markup ul {
    font-size: 18px;
    line-height: 180%;
    color: var(--neutral-500);
    margin-left: 16px;
  }

  .markup ol {
    font-size: 18px;
    line-height: 180%;
    color: var(--neutral-500);
    margin-left: 22px;
    margin-top: 8px;
  }
  
  .markup p {
    font-size: 18px;
    line-height: 180%;
    color: var(--neutral-500);
  }
  
  .markup a {
    color: var(--neutral-100) !important;
    text-decoration: underline;
  }
  
  .markup h1, h2, h3, h4, h5, h6, a {
    color: var(--neutral-100);
  }
  
  .markup h1 {
    font-family: 'Inter Bold';
    font-size: 34px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  
  .markup h2 {
    font-family: 'Inter Bold';
    font-size: 24px;
    margin-bottom: 8px;
    margin-top: 16px;
  }

  .markup h3 {
    font-family: 'Inter Bold';
    font-size: 20px;
    margin-bottom: 8px;
    margin-top: 16px;
  }

  .separator {
    background-color: var(--neutral-875);
    margin-top: 24px;
    margin-bottom: -32px;
    width: 100%;
    height: 1px;
  }

  .rightContainer {
    justify-content: flex-end;
  }

@media screen and (max-width: 670px) {
  .blogArticleContainer {
    padding: 108px 32px;
    padding-top: 124px;
  }

  .hideInMobile {
    display: none;
  }

  .header {
    padding: 90px 64px;
    padding-top: 138px;
  }

  .articleContainer {
    padding: 32px;
  }
}