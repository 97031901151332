.container {
  padding: 64px;
  padding-top: 41px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.logoContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.rightContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  column-gap: 16px;
}

.wrapper {
  padding: 24px 0px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.tag {
  color: var(--neutral-100);
  column-gap: 6px;
  padding: 1px 7px;
  min-height: 0px !important;
  height: 22px;
  font-size: 10px;
  font-family: "Inter SemiBold";
  border-color: var(--neutral-100);
  border-width: 1.5px;
  border-style: solid;
  border-radius: 50px;
  width: fit-content;
  margin-top: -2px;
  margin-bottom: -2px;
}

.link {
  font-family: "Inter Medium";
  color: var(--neutral-400) !important;
}

.miniH6 {
  font-size: 15px;
  margin-bottom: 6px;
}

.thirdy {
  width: 100%;
}

.borderedWrapper {
  margin-top: 32px;
  padding-top: 32px;
  border-top-width: 1.5px;
  border-top-style: solid;
  border-top-color: var(--neutral-800);
}

.noPadButton {
  padding: 0px !important;
  max-height: none !important;
  min-height: 0px !important;
}

.noPadButton:hover {
  opacity: 0.8;
}

.logo {
  margin-top: 2px;
  margin-left: 2px;
  pointer-events: none;
}

.logoText {
  margin-top: 2px;
  color: var(--neutral-100);
  font-size: 24px !important;
}

.blinker {
  animation: blinkAnimation 1s infinite;
  margin-bottom: 8px;
}

.typingIndicator {
  width: 6px;
  height: 18px;
  background-color: var(--neutral-500);
}

.logoTypingIndicator {
  margin-top: 2px;
  margin-left: 4px;
  width: 3px;
  height: 19px;
  background-color: var(--neutral-500);
}

@keyframes blinkAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.socialButton {
  min-height: 25px !important;
  max-height: 25px !important;
  min-width: 25px !important;
  max-width: 25px !important;
  padding: 0px !important;
}

.someWrapper {
  width: 100%;
}

@media screen and (max-width: 670px) {
  .container {
    padding: 8px 24px;
  }

  .wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 48px !important;
  }

  .borderedWrapper {
    margin-top: -24px;
  }

  .bottomText {
    text-align: center;
    width: 100%;
    margin-bottom: -24px;
    max-width: none;
  }

  .logo {
    min-width: 80px;
    max-width: 80px;
  }
}
