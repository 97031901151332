.container {
  padding-top: 40px;
  height: 100vh;
  background-color: var(--primary-10);
}

.darkContainer {
  background-color: var(--neutral-1050);
}

.loginContainer {
  width: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loginWrapper {
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: auto;
  row-gap: 8px;
  padding: 32px;
}

.ctaButton {
  column-gap: 6px;
  padding-right: 18px;
}

.signInWithGoogle {
  margin-top: 16px;
  width: 350px;
}

.signInWithGoogleLink {
  width: 100%;
}

.paragraph {
  text-align: center;
}

.signUpLinkContainer {
  margin-top: 16px;
}

.googleIcon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.separator {
  width: 100%;
  height: 1px;
}

.separatorContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 16px 0px;
  column-gap: 16px;
}

.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 16px;
}

.input {
  width: 100%;
}

.magicIcon {
  margin-right: 8px;
}

.signupWrapper {
  width: auto;
  padding: 12px;
  box-shadow: var(--shadow);
  border-radius: 0.75rem;
  background-color: var(--neutral-1000);
}

.coloredBlock {
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px;
  padding-top: 36px;
  border-radius: 0.75rem;
  width: 360px;
  height: 100%;
  background-color: var(--primary);
}

.inputError {
  border: 1.5px solid var(--red) !important;
}

.contentBlock {
  margin-left: 12px;
  padding: 64px;
  border-radius: 0.75rem;
  width: 500px;
  height: 100%;
}

.input {
  width: 100%;
}

.inputTitle {
  font-size: 14px;
  color: var(--neutral-500);
}

.title {
  margin-top: -5px;
  font-size: 26px;
}

.checkboxText {
  text-align: left;
  margin-top: -3px;
  font-size: 13px;
  min-width: 340px;
  line-height: 180%;
}

.checkboxRadioButton {
  width: 100%;
  justify-content: flex-start;
  padding: 0px;
}

.boxHeading {
  color: var(--neutral-1000);
  font-size: 26px;
}

.boxMessage {
  color: var(--neutral-800);
}

.rightContainer {
  justify-content: flex-end;
}

.bottomContainer {
  height: 100%;
  max-width: 296px;
  align-items: flex-end;
}

.carouselWrapper {
  margin-left: -20px;
  margin-bottom: 6px;
}

.reviewBlock {
  padding: 18px 24px;
  border-radius: 0.75rem;
  background-color: var(--primary-90);
}

.reviewTextBlock {
  width: auto;
}

.reviewMessage {
  color: var(--neutral-100);
  font-size: 14px;
  font-family: "Inter Medium";
  width: auto;
}

.reviewName {
  font-size: 13px;
  font-family: "Inter Bold";
  color: var(--neutral-100);
  margin-left: 4px;
  width: auto;
}

.fPimMH {
  width: 336px;
}

.reviewSubMessage {
  margin-left: 4px;
  margin-top: -5px;
  font-size: 12px;
  color: var(--neutral-100);
  width: auto;
}

.longButton {
  width: 100%;
}

.reviewPic {
  border-radius: 0.75rem;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  background-color: var(--neutral-100);
  overflow: hidden;
  box-shadow: var(--shadow);
}

@media screen and (min-width: 671px) {
  @media screen and (max-height: 915px) {
    .container {
      justify-content: flex-start;
      padding-top: 128px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .coloredBlock {
    display: none;
  }

  .contentBlock {
    margin-left: 0px;
    width: 500px;
  }
}

@media screen and (max-width: 670px) {
  .container {
    padding: 0px;
    padding-top: 24px;
    padding-bottom: 24px;

  }

  .checkboxText {
    min-width: 0px;
  }

  .button {
    width: 100%;
  }

  .contentBlock {
    padding: 48px 32px;
    border-radius: 0px;
    width: 100%;
  }

  .signupWrapper {
    width: 100%;
    border-radius: 0px;
  }

  @media screen and (max-height: 830px) {
    .container {
      justify-content: flex-start;
      padding-top: 84px !important;
    }
  }
}

@media screen and (max-width: 430px) {
  .signInWithGoogle {
    width: 100%;
  }

  .loginWrapper {
    align-self: stretch;
  }
}