.defaults {
  padding: 16px 18px;
  font-size: 15px;
  border-radius: 6px;
  color: var(--neutral-100);
  border: none;
  border-width: 1.5px;
  border-style: solid;
  border-color: var(--neutral-800);
  background-color: var(--neutral-875);
  outline: none;
  transition: ease 0.225s;
  align-self: baseline;
  min-height: 55px;
  max-height: 55px;
}

.darkDefaults {
  padding: 16px 18px;
  font-size: 15px;
  border-radius: 6px;
  color: var(--neutral-100);
  border: none;
  border-width: 1.5px;
  border-style: solid;
  border-color: var(--neutral-800);
  background-color: var(--neutral-875);
  outline: none;
  transition: ease 0.225s;
  align-self: baseline;
  min-height: 55px;
  max-height: 55px;
}

.defaults:focus {
  border-color: var(--primary);
  background-color: var(--neutral-900);
}

.darkDefaults:focus {
  border-color: var(--neutral-500);
  background-color: var(--neutral-900);
}

.defaults::placeholder {
  color: var(--neutral-600);
}

.big {
  font-size: 18px;
  padding: 22px 22px;
  min-height: 65px;
  max-height: 65px;
}

.small {
  padding: 12px 14px;
  min-height: 45px;
  max-height: 45px;
}

.medium {
  min-height: 45px !important;
  max-height: 45px !important;
  padding: 12px 14px !important;
}

@media screen and (max-width: 670px) {
  .big {
    font-size: 16px;
    padding: 18px;
  }
}

@media screen and (max-width: 430px) {
  .big {
    width: 100%;
  }
}