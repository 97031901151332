.defaults {
  font-family: "Inter Semibold";
  cursor: pointer;
  transition: ease 0.225s;
  color: var(--primary) !important;
  z-index: 150;
}

.defaults:hover {
  opacity: 0.8;
}

.big {
  font-size: 20px;
}

.small {
  font-size: 15px;
}

.underlined {
  text-decoration: underline !important;
}

.white {
  color: var(--neutral-100) !important;
}

.shineContainer {
  position: relative;
  overflow: hidden;
  display: inline-flex; /* Add this */
  background-clip: text; /* Add this to clip the background to the text */
}

.shineLink {
  text-decoration: none;
}

.shineLink::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent, var(--neutral-1000), transparent);
  transform: skewX(-20deg);
  animation: shine 3s linear infinite;
}

@keyframes shine {
  0% {
    left: -100%;
  }
  30% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}