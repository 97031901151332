.blogContainer {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  
  .blogArticle {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 24px;
    row-gap: 10px;
    border-radius: 0.5rem;
    transition: all 0.225s ease;
    border: 1.5px solid var(--neutral-800);
}

.separator {
  background-color: var(--neutral-875);
  margin-top: 24px;
  margin-bottom: -32px;
  width: 100%;
  height: 1px;
}

.infoContainer {
  width: auto;
  margin-bottom: -8px;
}

.blogArticle:hover {
    background-color: var(--neutral-875);
    box-shadow: var(--shadow);
}

.summary {
  margin: 0px;
  padding: 0px;
  margin-bottom: -4px;
}

  .background {
    border-radius: 0.5rem;
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit: cover;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .gradientBackground {
    border-radius: 0.5rem;
    background: linear-gradient(to top, var(--neutral-100), transparent 40%);
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .articleTitle {
    color: #fff;
  }

  .est {
    color: var(--neutral-800);
  }

  .rightContainer {
    justify-content: flex-end;
  }

  .heading {
    white-space: normal;
    line-height: 170%;
  }

  .paginationButton {
    margin-top: -9px;
    min-width: 28px;
    max-width: 28px;
    min-height: 28px;
    max-height: 28px;
    padding: 0px;
    border-radius: 4px;
    border: 1.5px solid var(--neutral-800);
    box-shadow: var(--shadow);
  }
  
  .paginationButton:hover {
    background-color: var(--neutral-875);
  }

  .disabledPaginationButton {
    opacity: 0.4;
    background-color: var(--neutral-1000) !important;
    cursor: not-allowed;
  }
  
  /* Define media queries for responsiveness */
  @media (max-width: 768px) {
    .blogContainer {
      grid-template-columns: 1fr;
    }
  }