.logoContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: -4px;
}

.smallLogoContainer {
}

.link {
  pointer-events: all;
}

.logo {
  pointer-events: none;
}

.logoText {
  font-size: 22px !important;
  font-family: "Sora Semibold";
}

.invertText {
  color: #fff;
}

.smallText {
  font-size: 20px !important;
}

.logo {
  min-height: 32px;
  max-height: 32px;
  margin-bottom: 0px !important;
  column-gap: 10px;
}

.smallIcon {
  min-height: 30px;
  max-height: 30px;
  max-width: none;
}

.blinker {
  animation: blinkAnimation 1s infinite;
  margin-bottom: 8px;
}

.sparkles {
  color: var(--primary);
}

.logoTypingIndicator {
  margin-top: 2px;
  margin-left: 4px;
  width: 3px;
  height: 19px;
  background-color: var(--neutral-600);
}

.smallLogoTypingIndicator {
  margin-top: 2px;
  margin-left: 4px;
  width: 2.5px;
  height: 15.5px;
  background-color: var(--neutral-600);
}

@media screen and (max-width: 671px) {
  .logoContainer {
    margin-top: 4px;
  }
}

@keyframes blinkAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
