.defaults {
  white-space: nowrap;
  font-family: "Inter SemiBold";
  line-height: 100%;
  color: var(--neutral-100);
  margin: 0;
  font-size: 28px;
  letter-spacing: normal;
  display: flex;
  column-gap: 6px;
}

.hide {
  opacity: 0;
  position: fixed;
  top: -1000px;
  font-size: 28px;
  letter-spacing: -0.8px;
  white-space: nowrap;
  font-family: "Inter SemiBold";
  line-height: 100%;
}

.smallSpinner {
  margin-left: 4px;
}

.editText {
  cursor: pointer;
}

.tooltip {
  color: rgba(255, 255, 255, 1) !important;
  background-color: rgba(24, 24, 24, 1) !important;
  padding: 8px 11px;
  font-size: 12px;
  font-family: "Inter Regular";
  border-radius: 4px;
}

.input {
  text-align: left;
  width: 100%;
  margin: 0px;
  margin-top: -6px;
  margin-bottom: -7px;
  margin-left: -4px;
  min-height: 0px;
  max-height: none;
  padding: 4px;
  border: none;
  background-color: transparent;
  white-space: nowrap;
  font-family: "Inter SemiBold";
  line-height: 100%;
  color: var(--neutral-100);
  font-size: 22px;
  letter-spacing: -0.8px;
  pointer-events: all;
}

@media screen and (max-width: 670px) {
  .defaults {
    font-size: 24px;
  }
}
