.defaults {
    min-width: 114px;
    max-width: 114px;
    min-height: 114px;
    max-height: 114px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    pointer-events: none;
    position: relative;
}

.viewDefaults {
    background-color: var(--neutral-875);
    min-width: 114px;
    max-width: 114px;
    min-height: 114px;
    max-height: 114px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    z-index: 14000;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    bottom: 0;
}

.bordered {
    outline: 8px solid var(--neutral-1000);
}

.input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: all;
    z-index: 15000;
    opacity: 0;
    cursor: pointer;
}

.hiddenOverlay {
    position: relative;
    z-index: 19000;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: all;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    border-radius: 50%;
    transition: opacity 0.2s ease-in-out;
}

.hiddenOverlay:hover {
    opacity: 1;
}

.small {
    min-width: 35px;
    max-width: 35px;
    min-height: 35px;
    max-height: 35px;
}

.smallView {
    min-width: 33px;
    max-width: 33px;
    min-height: 33px;
    max-height: 33px;
}

.initials {
    color: var(--neutral-400);
}

.smallText {
    font-family: "Inter SemiBold";
    font-size: 14px !important;
}