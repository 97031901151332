.tagGrid {
    margin-top: 6px;
    display: flex;
    column-gap: 8px;
  }
  .tagBubble {
    align-items: center;
    background-color: var(--primary-90);
    padding: 4px 9px;
    border-radius: 50px;
    white-space: nowrap;
  }

  .lightTagBubble {
    align-items: center;
    background-color: var(--primary-10);
    padding: 4px 9px;
    border-radius: 50px;
    white-space: nowrap;
  }

  .tagText {
    color: #fff;
    font-family: "Inter Semibold";
    font-size: 11px;
  }

  .lightTagText {
    color: var(--primary);
    font-family: "Inter Semibold";
    font-size: 11px;
  }
  