@import "~rc-drawer/assets/index.css";

:root {
  --neutral-100: rgb(15 23 41);
  --neutral-200: rgb(32, 32, 32);
  --neutral-300: rgb(48, 48, 48);
  --neutral-400: rgb(81, 81, 81);
  --neutral-500: #71717a;
  --neutral-600: rgb(184, 184, 184);
  --neutral-700: rgb(200, 200, 200);
  --neutral-800: #e2e8f0;
  --neutral-850: rgb(232, 232, 232);
  --neutral-875: #f4f4f5;
  --neutral-900: rgb(255, 255, 255);
  --neutral-950: rgb(255, 255, 255);
  --neutral-1000: rgb(255, 255, 255);
  --neutral-1050: rgb(255, 255, 255);
  --neutral-1000-alpha: rgba(255, 255, 255, 0.65);
  --accent: rgb(16, 16, 16);
  --primary: rgb(15 23 41);
  --secondary: rgb(81, 81, 81);
  --primary-10: #ebf0f8;
  --primary-20: #dfe7f7;
  --primary-90: #f1f5f9;
  --deep-sea: rgb(16, 16, 16);
  --shallow-sea: #171717;
  --blue: #1473e6;
  --gold: #f0e933;
  --red: rgb(255, 0, 0);
  --light-red: rgba(255, 0, 0, 0.1);
  --green: rgb(6, 194, 88);
  --light-green: rgba(6, 194, 88, 0.1);
  
  --shadow: 0px 2px 4px 0px rgba(226, 232, 240, 0.35);
  --right-shadow: 2px 0px 2px 0px rgba(226, 232, 240, 0.35);

  --large-shadow: 1px 1px 4px 1px rgba(193, 198, 205, 0.45);
}

.emotion-css-cache-r6z5ec {
  z-index: 1500 !important;
  position: fixed !important;
}

/* Hide scrollbar for Chrome on Windows */
::-webkit-scrollbar {
  width: 0em; /* Adjust the width as per your preference */
  height: 0em;
}

::-webkit-scrollbar-track {
  background: transparent; /* Set the background color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: transparent; /* Set the color of the scrollbar thumb */
}

@font-face {
  font-family: "Sora ExtraLight";
  src: url("/public/assets/typefaces/Sora/ExtraLight.ttf") format("truetype");
  font-weight: 100;
  font-style: 100;
}

@font-face {
  font-family: "Sora Light";
  src: url("/public/assets/typefaces/Sora/Light.ttf") format("truetype");
  font-weight: 200;
  font-style: 200;
}

@font-face {
  font-family: "Sora Thin";
  src: url("/public/assets/typefaces/Sora/Thin.ttf") format("truetype");
  font-weight: 300;
  font-style: 300;
}

@font-face {
  font-family: "Sora Regular";
  src: url("/public/assets/typefaces/Sora/Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: 400;
}

@font-face {
  font-family: "Sora Medium";
  src: url("/public/assets/typefaces/Sora/Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: 500;
}

@font-face {
  font-family: "Sora SemiBold";
  src: url("/public/assets/typefaces/Sora/SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: 600;
}

@font-face {
  font-family: "Sora Bold";
  src: url("/public/assets/typefaces/Sora/Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: 700;
}

@font-face {
  font-family: "Sora ExtraBold";
  src: url("/public/assets/typefaces/Sora/ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: 800;
}

@font-face {
  font-family: "Inter ExtraLight";
  src: url("/public/assets/typefaces/Inter/ExtraLight.ttf") format("truetype");
  font-weight: 100;
  font-style: 100;
}

@font-face {
  font-family: "Inter Light";
  src: url("/public/assets/typefaces/Inter/Light.ttf") format("truetype");
  font-weight: 200;
  font-style: 200;
}

@font-face {
  font-family: "Inter Thin";
  src: url("/public/assets/typefaces/Inter/Thin.ttf") format("truetype");
  font-weight: 300;
  font-style: 300;
}

@font-face {
  font-family: "Inter Regular";
  src: url("/public/assets/typefaces/Inter/Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: 400;
}

@font-face {
  font-family: "Inter Medium";
  src: url("/public/assets/typefaces/Inter/Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: 500;
}

@font-face {
  font-family: "Inter SemiBold";
  src: url("/public/assets/typefaces/Inter/SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: 600;
}

@font-face {
  font-family: "Inter Bold";
  src: url("/public/assets/typefaces/Inter/Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: 700;
}

@font-face {
  font-family: "Inter ExtraBold";
  src: url("/public/assets/typefaces/Inter/ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: 800;
}

@font-face {
  font-family: "Inter Black";
  src: url("/public/assets/typefaces/Inter/Black.ttf") format("truetype");
  font-weight: 900;
  font-style: 900;
}

@font-face {
  font-family: "Space Grotesk Light";
  src: url("/public/assets/typefaces/Space_Grotesk/Light.ttf")
    format("truetype");
  font-weight: 200;
  font-style: 200;
}

@font-face {
  font-family: "Space Grotesk Regular";
  src: url("/public/assets/typefaces/Space_Grotesk/Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: 400;
}

@font-face {
  font-family: "Space Grotesk Medium";
  src: url("/public/assets/typefaces/Space_Grotesk/Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: 500;
}

@font-face {
  font-family: "Space Grotesk SemiBold";
  src: url("/public/assets/typefaces/Space_Grotesk/SemiBold.ttf")
    format("truetype");
  font-weight: 600;
  font-style: 600;
}

@font-face {
  font-family: "Space Grotesk Bold";
  src: url("/public/assets/typefaces/Space_Grotesk/Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: 700;
}

.body {
  font-family: "Inter Regular";
  background-color: var(--neutral-1000);
}

html {
  background-color: var(--neutral-1000);
}

b {
  color: var(--primary) !important;
}

.drawer {
}

.drawer-open {
}

a {
  text-decoration: none;
}

.darkMode {
  --neutral-1050: #0d0d0f;
  --neutral-1000-alpha: rgba(9, 9, 11, 0.45);
  --neutral-1000: rgb(9, 9, 11);
  --neutral-950: #09090b;
  --neutral-900: #09090b;
  --neutral-875: #18181a;
  --neutral-850: #27272a;
  --neutral-800: rgb(48, 48, 48);
  --neutral-700: rgb(159, 159, 159);
  --neutral-600: rgb(184, 184, 184);
  --neutral-500: rgb(200, 200, 200);
  --neutral-400: rgb(225, 225, 225);
  --neutral-300: rgb(243, 243, 243);
  --neutral-200: rgb(248, 248, 248);
  --neutral-100: rgb(255, 255, 255);
  --accent: rgb(255, 255, 255);
  --primary: rgb(255, 255, 255);
  --secondary: rgb(225, 225, 225);
  --primary-10: #081124;
  --primary-20: #0b1732;
  --primary-90: rgb(1, 6, 14);
  --blue: #1e81fa;
  --red: rgb(218, 32, 32);
  --deep-sea: rgb(255, 255, 255);
  --shallow-sea: rgb(243, 243, 243);

  --shadow: 0px 2px 4px 1px rgba(48, 48, 48, 0.15);
  --right-shadow: 2px 0px 2px 0px rgba(48, 48, 48, 0.15);

  --large-shadow: 0px 2px 4px 0px rgb(48, 48, 48);
}

.tooltip {
  color: rgba(255, 255, 255, 1) !important;
  background-color: rgba(24, 24, 24, 1) !important;
  padding: 8px 11px;
  font-size: 12px;
  font-family: "Inter Regular";
  border-radius: 4px;
}
