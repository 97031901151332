.defaults {
  font-family: "Inter SemiBold";
  line-height: 130%;
  color: var(--neutral-100);
  margin: 0;
  font-size: 34px;
  letter-spacing: normal;
}

@media screen and (max-width: 670px) {
  .defaults {
    font-size: 28px;
  }
}
