.defaults {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 100000;
    background-color: rgba(0, 0, 0, 0.35);
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fadeIn {
    /* Add styles for the fade-in animation */
    opacity: 1;
  }
  
  .fadeOut {
    pointer-events: none;
    /* Add styles for the fade-out animation */
    opacity: 0;
  }

  @media screen and (max-width: 430px) {

    .defaults {
      align-items: flex-end;
    }
  }