/* custom-ckeditor-styles.css */

/* Body Styles */
body {
  font-family: "Inter Regular";
}

/* Change the background color of CKEditor content area */
.ck-editor__editable {
  cursor: text !important;
  border: 1.5px solid var(--neutral-800);
  border-radius: 0.5rem;
  background-color: var(
    --neutral-1000
  ) !important; /* Change this color to your desired background color */
}


.ck {
  border: none !important;
}

.ck-editor__editable {
  border-color: transparent !important;
  box-shadow: none !important;
  padding: 0px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter Bold";
  color: var(--neutral-100);
}

h1 {
  font-size: 34px;
  margin-top: 16px;
  margin-bottom: 8px;
}

h2 {
  font-size: 24px;
  margin-bottom: 8px;
  margin-top: 16px;
}

h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

/* Text Styles */
p {
  color: var(--neutral-500);
  font-size: 18px;
  line-height: 180%;
}

/* Links */
a {
  color: #007bff;
  text-decoration: underline;
}

a:hover {
  color: #0056b3;
}

/* Blockquote */
blockquote {
  border-left: 4px solid #ccc;
  padding: 10px;
  margin: 0;
  margin-bottom: 15px;
  font-style: italic;
}

/* Code */
code {
  font-family: Consolas, monospace;
  background-color: #f5f5f5;
  padding: 2px 4px;
  border-radius: 4px;
}
