.line {
  width: 22px;
  height: 2px;
  border-radius: 4px;
  background-color: var(--neutral-100);
  transition: all 0.225s ease;
}

.mobileButtonContainer {
  margin-bottom: -10px;
  max-height: 45px;
  max-width: 45px;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
  position: static !important;
}

.logo {
  margin-top: 2px;
  margin-left: 2px;
  pointer-events: none;
}

.logoText {
  margin-top: 2px;
  color: var(--neutral-1000);
  font-size: 24px !important;
}

.blinker {
  animation: blinkAnimation 1s infinite;
  margin-bottom: 8px;
}

.typingIndicator {
  width: 6px;
  height: 18px;
  background-color: var(--neutral-500);
}

.logoTypingIndicator {
  margin-top: 2px;
  margin-left: 4px;
  width: 3px;
  height: 19px;
  background-color: var(--neutral-500);
}

@keyframes blinkAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mobileButton {
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 8px;
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-right: -2px;
  flex-direction: column;
}

.fixedMobileButton {
  position: fixed;
  top: 9px;
  right: 12px;
  z-index: 15;
}

.mobileMenu {
  position: absolute;
  z-index: 1400;
  top: -50px;
  left: 0px;
  right: 0px;
  overflow: hidden;
  background-color: rgb(16, 16, 16);
  opacity: 0;
  transition: all 0.225s ease;
  pointer-events: none;
}

.ctaButton {
  column-gap: 6px;
  width: 100%;
}

.cleanButton {
  padding: 0px;
  min-height: 0px;
  max-height: none;
  border-width: 0px;
}

.mobileNavButtonContainer {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.maxWidthButton {
  width: 100%;
  font-size: 18px;
  color: #fff;
  font-family: "Inter SemiBold";
}

.mobileMenuWrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.animateNavLine:first-child {
  transform: rotate(45deg) translate(3.5px, 3.5px);
  background-color: #fff;
}

.animateNavLine:last-child {
  transform: rotate(-45deg) translate(3.5px, -3.5px);
  background-color: #fff;
}

.openMobileNavMenu {
  top: 0px;
  opacity: 1;
  pointer-events: all;
}

.accordions {
  display: flex;
  flex-direction: column;
}
