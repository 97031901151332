.defaults {
    border-width: 1.5px;
    border-style: solid;
    border-color: var(--neutral-800);
    border-radius: 0.5rem;
    background-color: var(--neutral-1000);
    padding: 24px;
    width: 250px;
    position: relative;
    transition: 225ms all ease;
}

.defaults3 {
    border-width: 1.5px;
    border-style: solid;
    border-color: var(--neutral-800);
    border-radius: 0.5rem;
    background-color: var(--neutral-1000);
    padding: 24px;
    width: 100%;
    position: relative;
    transition: 225ms all ease;
}

.image {
    width: 75px;
    height: 75px;
}

.transparentBackground {
    background-color: transparent !important;
    border-color: transparent;
}

.defaults:hover {
    border-color: var(--secondary);
}

.selected {
    border-color: var(--primary) !important;
}

.smallTitle {
    font-size: 14px;
}

.iconContainer {
    border-width: 1.5px;
    border-style: solid;
    border-color: var(--neutral-800);
    border-radius: 0.5rem;
    background-color: var(--neutral-1000);
    min-width: 45px;
    min-height: 45px;
    max-width: 45px;
    max-height: 45px;
    align-items: center;
    justify-content: center;
}

.checkbox {
    position: absolute;
    z-index: 14;
    align-items: center;
    justify-content: center;
    top: 16px;
    right: 16px;
    border-width: 1.5px;
    border-style: solid;
    border-color: var(--neutral-800);
    border-radius: 50%;
    background-color: var(--neutral-1000);
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
}

.inverted {
    filter: invert(1);
}

.filledCheckbox {
    border-color: var(--primary);
    background-color: var(--primary);
}

.buttonWrapper {
    max-height: none;
    min-height: 0px;
    padding: 0px;
    margin: 0px;
}

.buttonWrapper3 {
    width: 100%;
    max-height: none;
    min-height: 0px;
    padding: 0px;
    margin: 0px;
}

.paragraph {
    white-space: initial;
    text-align: center;
}

.swampCore {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .paragraph {
        text-align: left;
    }

    .buttonWrapper {
        width: 100%;
    }

    .defaults {
        width: 100%;
        flex-direction: row;
    }

    .swampCore {
        align-items: flex-start;
        margin-bottom: -6px;
        margin-right: 24px;
    }
}

@media screen and (max-width: 600px) {
    h6 {
        text-align: left;
    }
}
