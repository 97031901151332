.squareButton {
  padding: 0px;
  min-width: 28px;
  max-width: 28px;
  min-height: 28px;
  max-height: 28px;
  border-radius: 6px;
}

.copyChatButton:hover {
  background-color: var(--neutral-875);
}

.small {
  min-width: 22px;
  max-width: 22px;
  min-height: 22px;
  max-height: 22px;
  margin-right: -4px;
}
