.contentContainer {
  background-color: var(--neutral-1000);
  padding: 24px;
}

.headerContainer {
  row-gap: 4px;
}

.whiteIcon {
  color: var(--neutral-1000);
}

.fullWidth {
  width: 100%;
}

.titleContainer2 {
  flex-direction: row;
  margin-bottom: -4px;
}

.link {
    color: var(--neutral-100);
    cursor: pointer;
}

.termsText {
    margin-top: 16px;
}

.iconContainer {
  padding-top: 1px;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  border-radius: 50%;
  background-color: var(--blue);
}

.termsText {
  max-width: 350px;
}

.welcomeTitle {
  font-size: 22px;
}

.errorBorder {
  border-color: var(--red) !important;
}

.errorIcon {
  margin-top: 5px;
}

.errorContainer {
  align-items: flex-start;
  column-gap: 6px;
  margin-top: 4px;
  margin-bottom: 8px;
}

.errorText {
  color: var(--red);
  text-align: left;
  max-width: 350px;
}

.input {
  width: 100%;
}

.button {
  width: 100%;
  min-width: 350px;
}

.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 16px;
}

.separator {
  width: 100%;
  height: 1px;
}

.separatorContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 16px 0px;
  column-gap: 16px;
}

.googleIcon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.signInButton {
  width: 100%;
  min-width: 350px;
}

.magicIcon {
  margin-right: 8px;
}

.paddedContainer {
  padding: 32px;
  overflow: scroll;
}

.smallIconContainer {
  margin-left: -2px;
  padding-top: 1px;
  align-items: center;
  justify-content: center;
  min-width: 14px;
  max-width: 14px;
  min-height: 14px;
  max-height: 14px;
  border-radius: 50%;
  background-color: var(--blue);
}

.separator {
  width: 100%;
  height: 1px;
}

.buttonContainer {
  align-items: center;
  justify-content: flex-start;
  background-color: var(--neutral-1000);
  padding: 16px;
}

.buttonWrapper {
  align-items: center;
  justify-content: flex-end;
}

.squareButton {
  min-width: 45px;
  max-width: 45px;
  padding: 0px;
}

@media screen and (max-width: 430px) {
  .hideInMobile {
    display: none;
  }

  .separator {
    display: none;
  }

  .buttonContainer {
    padding: 24px;
    padding-top: 0px !important;
  }
}

@media screen and (max-width: 490px) {
  .hideInMobile2 {
    display: none;
  }

  .fullWidthButton {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}
