.container {
  max-width: 1200px;
  margin-bottom: 50px;
  padding: 32px 32px;
  padding-top: 122px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.wrapper {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.plusContainer {
  position: absolute;
  top: 314px;
  left: 220px;
  opacity: 0.65;
}

.plus {
  color: var(--primary);
  width: 14px;
  height: 14px;
}

.largePlusContainer {
  position: absolute;
  top: 384px;
  left: calc(50vw + 320px);
}

.largePlus {
  color: var(--primary);
  width: 24px;
  height: 24px;
}

.flare {

}

.otherMessage {
  color: var(--neutral-100);
  font-size: 13px;
}

.flareDarkMode {
  pointer-events: none;
  -webkit-transform: scale3d(1.4, 1, 1) rotateX(0deg) rotateY(60deg)
    rotateZ(0deg);
  transform: scale3d(1.4, 1, 1) rotateX(0deg) rotateY(60deg) rotateZ(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  opacity: 0.45;
  position: absolute;
  z-index: 11500;
  left: 0px;
  top: 0px;
  right: 0%;
  bottom: 0%;
  background-image: url("https://draftsai.com/assets/images/flare.svg");
  background-position: 100% 0px, 0px 0px;
  background-size: 100%, 100%;
  width: 100%;
  background-repeat: no-repeat, repeat;
  -webkit-mask-image: linear-gradient(to bottom, black 0%, transparent 50%);
  mask-image: linear-gradient(to bottom, black 0%, transparent 50%);
}

span.boldSpan {
  font-family: "Inter Bold";
}

.ctaInputError {
  border-color: var(--red) !important;
}

.errorIcon {
  min-width: 14px;
  max-width: 14px;
  min-height: 14px;
  max-height: 14px;
}

.errorContainer {
  justify-content: flex-start;
  max-width: 240px;
  column-gap: 4px;
  margin-top: 8px;
}

.errorMessage {
  white-space: nowrap;
  color: var(--red);
}

.autoWidth {
  width: auto;
  align-items: flex-start;
}

.noPadButton {
  padding: 0px !important;
  min-height: 0px;
  max-height: none;
  color: var(--neutral-100);
}

.rightContainer {
  width: 100%;
  justify-content: flex-end;
}

.mobileHeaderMedia {
  display: none;
  min-width: 100% !important;
  max-width: 100% !important;
  margin-bottom: 32px;
}

.subheading {
  color: var(--neutral-1000);
  border-width: 1.5px;
  border-style: solid;
  border-color: var(--neutral-1000);
  column-gap: 6px;
  padding: 6px 10px;
  padding-right: 6px;
  min-height: 0px !important;
  font-size: 13px;
  background-color: var(--neutral-100);
  border-radius: 50px;
  font-family: "Inter SemiBold";
}

.subheading:hover {
  color: var(--neutral-1000) !important;
  text-decoration: underline;
}

.headerMedia {
  min-width: 650px;
  max-width: 650px;
  border-radius: 16px;
}

.ctaButton {
  column-gap: 6px;
  padding-right: 18px;
  border: 1.5px solid var(--primary-10) !important;
}

.block {
  padding: 48px;
  max-width: 460px;
  width: 100%;
  border-radius: 0.75rem;
  background-color: rgb(15 23 41);
}

.blockLight {
  max-width: 33.33%;
  width: auto;
  flex-direction: column;
  padding: 24px;
  border-radius: 0.75rem;
  border: 1.5px solid var(--neutral-800);
  box-shadow: var(--shadow);
}

.checkTitle {
  color: #fff;
}

.whiteSmallHeading {
  color: #fff;
}

.whiteParagraph {
  margin-top: 6px;
  color: #fff;
}

.message {
  color: var(--secondary);
  font-size: 22px;
  text-align: center;
  max-width: 600px;
}

.sparkleContainer {
  display: inline-block;
  margin-right: 8px;
}

.sparkle {
  display: inline-flex;
  margin-bottom: -4px;
  width: 40px;
  height: 40px;
}

.header {
  white-space: normal !important;
  font-size: 58px !important;
  max-width: 800px !important;
  line-height: 140%;
  text-align: center;
}

.headerDarkMode {
}

.circleContainer {
  width: 400px;
  max-height: 100px;
  overflow: hidden;
}

.curvedLine {
  width: 400px;
  height: 400px;
  background-color: transparent;
  border-width: 4px;
  border-style: solid;
  border-color: black;
  border-radius: 50%;
}

.circleOverlay {
  width: 400px;
  height: 50px;
  margin-top: -400px;
  background-image: linear-gradient(to bottom, transparent, white);
}

.background {
  background-image: linear-gradient(to top, var(--neutral-1000), var(--neutral-1000));
}

.appImage {
  border-radius: 0.5rem;
  border: 1.5px solid var(--neutral-800);
}

.appImageWrapper {
  border-radius: 0.5rem;
  padding: 8px;
  border: 1.5px solid var(--neutral-800);
  background-color: var(--neutral-875);
}

.laurelWreath {
  width: 25px;
  height: 50px;
  object-fit: cover;
  filter: invert(1);
}

.separatorDot {
  color: var(--neutral-700);
  font-size: 10px !important;
}

.underline {
  text-decoration: underline;
}

.ctaButtonContainer {
  width: fit-content;
  padding: 3px;
  border: 1.5px solid var(--neutral-800);
  border-radius: 0.7rem;
  background-color: var(--neutral-875);
}

.checkList {
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
}

.initialPresentationLine {
  width: 4px;
  height: 250px;
  background-image: linear-gradient(to bottom, transparent, var(--neutral-100));
}

.lastPresentationLine {
  width: 4px;
  height: 250px;
  background-image: linear-gradient(to top, transparent, var(--neutral-100));
}

.presentationLine {
  width: 4px;
  height: 150px;
  background-color: var(--neutral-100);
}

.contentContainer {
  margin-top: -2px;
}

.presentationContainer {
  max-height: 38px;
  min-height: 38px;
}

.presentationIcon {
  min-width: 38px;
  min-height: 38px;
  max-width: 38px;
  max-height: 38px;
  margin-left: -17px;
  background-color: var(--neutral-100);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.seconds {
  font-family: 'Inter ExtraBold';
}

.strike {
  text-decoration: line-through !important;
}

.categoryTitle {
  margin-top: -6px !important;
  white-space: normal !important;
}

.sliderContainer {
  margin-top: -185px;
  max-width: 1200px;
  padding: 32px;
}

.emailInput {
  min-width: 250px;
}

/* styles.css */
.background-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
}

.gradient-top-right {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50%;
  background: linear-gradient(to bottom left, rgba(255, 0, 0, 0.7), transparent);
}

.gradient-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 50%;
  background: linear-gradient(to top right, rgba(0, 0, 255, 0.7), transparent);
}

.waitlistContainer {
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}

.orText {
  text-align: center;
}

.integrationsContainer {
  position: relative;
  z-index: 2;
}

.topContainer {
  position: relative;
  z-index: 2;
  margin-top: -380px;
}

.integrationsBackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.subtitle {
  font-size: 38px;
  font-family: "Inter Bold";
  max-width: 100%;
  text-align: center;
  white-space: normal;
  line-height: 150%;
}

.gridContainer {
  position: relative;
  z-index: 14;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconContainer {
  align-self: baseline;
  width: auto;
  padding: 12px;
  border-radius: 0.5rem;
  background-color: #dfe7f7;
}

.whiteLink {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff !important;
  column-gap: 8px;
}

.gridRow {
  display: flex;
  align-items: center;
}

.gridCell {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gridSeparator {
  width: 1px;
  height: 250px;
  margin: 0 10px;
}

.greenCheck {
  margin-right: 6px;
}

.miniTitle {
  color: var(--primary);
  font-size: 16px;
}

.gridSeparatorHorizontal {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 750px;
  max-width: 750px;
  height: 1px;
  margin: 10px 0;
  margin-top: -15px;
  margin-bottom: -15px;
}

.gridIcon {
  width: 44px;
  height: 44px;
  transition: all 0.225s ease;
}

.gridIcon:hover {
  opacity: 0.85;
}

.fillGrid {
  width: 500px;
  height: 1px;
}

.bubbleWrapper {
  position: absolute;
  height: 750px;
  top: 0;
  left: 0;
  right: 0;
}

.blockContainer {
  position: relative;
  z-index: 2;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.accordionTitle {
  margin: 0px;
}

.accordionButton {
  margin: 0px;
  padding: 24px 12px;
}

.accordionItem {
  border-color: var(--neutral-800);
}

.accordionPanel {
  padding: 24px 12px;
  padding-top: 0px;
}

.bubbleBackground {
  background-image: linear-gradient(var(--neutral-875) .1em, transparent .1em), linear-gradient(90deg, var(--neutral-875) .1em, transparent .1em);
  background-size: 2em 2em;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: -1px;
  pointer-events: none;
}

.bubbleColorBg {
  background-image: linear-gradient(to top, transparent 60%, var(--primary-90));
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.bubbleGradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to bottom, transparent 0%, var(--neutral-1000));
}

.primaryColor {
  font-family: "Inter Medium" !important;
  color: var(--primary);
}

.secondaryColor {
  color: var(--secondary);
}

.maxWidthPrimary {
  max-width: 390px;
}

.bPrimary {
  font-family: "Inter Bold" !important;
}

.appImageGradient {
  background: linear-gradient(to top, var(--neutral-1000) 50%, transparent 80%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

@media screen and (max-width: 1000px) {

.appImageContainer {
  overflow: hidden;
  width: calc(100vw - 24px);
  margin-left: 24px;
}

  .appImageWrapper {
    min-width: 1100px !important;
    max-width: 1100px !important;
  }

  .desktopHeaderMedia {
    display: none;
  }

  .mobileHeaderMedia {
    display: flex !important;
  }
}

@media screen and (max-width: 750px) {
  .featureGrid {
    flex-direction: column;
  }
}

@media screen and (max-width: 670px) {

  .blockContainer {
    flex-direction: column;
    width: 100%;
  }

  .block {
    padding: 32px;
    width: 100%;
    max-width: none;
  }

  .blockLight {
    padding: 24px;
    width: 100%;
    max-width: none;
  }

  .gridCell {
    width: 100px;
    height: 100px;
  }
  
  .gridSeparator {
    height: 125px;
    margin: 0 5px;
  }
  
  .gridSeparatorHorizontal {
    min-width: 375px;
    max-width: 375px;
    margin: 5px 0;
    margin-top: -7.5px;
    margin-bottom: -7.5px;
  }
  
  .gridIcon {
    width: 50px;
    height: 50px;
  }
  
  .fillGrid {
    width: 175px;
  }

  .otherMessage {
    display: none;
  }

  .hideInMobile {
    display: none;
  }

  .container {
    padding-top: 100px;
  }

  .header {
    font-size: 42px !important;
    max-width: 500px !important;
  }

  .subtitle {
    font-size: 32px !important;
  }

  .secondaryColor {
    font-size: 18px !important;
  }

  .sparkleContainer {
    margin-right: 5px;
  }

  .sparkle {
    margin-bottom: -2px;
    width: 24px;
    height: 24px;
  }

  .message {
    font-size: 18px;
    max-width: 450px;
  }
}

@media screen and (max-width: 430px) {

  .waitlistContainer {
    width: 100%;
    flex-direction: column !important;
    align-items: initial;
    row-gap: 24px;
  }

  .container {
    margin-top: -20px;
    padding: 128px 24px;
    padding-bottom: 64px;
  }

  .emailInput {
    width: 100%;
    min-width: 0px;
  }

  .orText {
    margin-top: -8px;
    margin-bottom: -8px;
  }

  .header {
    font-size: 28px !important;
  }

  .subtitle {
    font-size: 24px !important;
  }

  .secondaryColor {
    font-size: 16px !important;
  }
}
