.defaults {
  color: var(--neutral-100) !important;
  font-family: "Inter Medium" !important;
  background-color: var(--neutral-1000) !important;
  border-color: var(--neutral-800) !important;
  box-shadow: var(--shadow);
}

.defaults:hover {
  background-color: var(--neutral-875) !important;
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.65;
}

.disabled:hover {
  opacity: 0.65;
  background-color: var(--neutral-1000) !important;
  filter: brightness(1);
}